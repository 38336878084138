import { UsermavenClient, UsermavenOptions, usermavenClient } from '@usermaven/sdk-js';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const isProduction = process.env.NODE_ENV === 'production';

const usermavenOpts: UsermavenOptions = {
  key: 'UMGLOx0Bav',
  tracking_host: 'https://events.usermaven.com',
  autocapture: true,
};

export type UseUsermaven = {
  usermaven: UsermavenClient | null;
};

export function useUsermaven(): UseUsermaven {
  const usermaven = useMemo(() => (isProduction && usermavenClient(usermavenOpts)) || null, []);
  return { usermaven };
}

export function usePageView(usermaven: UsermavenClient | null) {
  const location = useLocation();

  useEffect(() => {
    if (isProduction && usermaven) usermaven.track('pageview');
  }, [location, usermaven]);
}
