import { Steps } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ShopifyStatus } from '../../../../types/channel';

const { Step } = Steps;

type ShopifyStatusStepsProps = {
  status: ShopifyStatus;
};

const ShopifyStatusSteps: React.FC<ShopifyStatusStepsProps> = ({ status }) => {
  const { t } = useTranslation();

  return (
    <Steps direction="vertical" current={1} className="shopify-status__steps">
      <Step
        title={status.installed ? t('channel:appInstalled') : t('channel:appNotInstalled')}
        description={!status.installed && t('channel:appNotInstalledDesc')}
        status={status.installed ? 'finish' : 'error'}
      />
      <Step
        title={
          status.status === 'connected' ? t('channel:authorizedAccess') : t('channel:deniedAccess')
        }
        description={
          (status.status === 'disconnected' || status.status === 'connected') && status.installed
            ? undefined
            : t('channel:deniedAccessDesc')
        }
        status={status.status === 'connected' ? 'finish' : 'error'}
      />
    </Steps>
  );
};

export default ShopifyStatusSteps;
