import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../reducers';
import { usePageView, useUsermaven } from '../../../hooks/useUsermaven';
import { isReceiver } from '../../../utils/UserUtils';

const UsermavenTracking: React.FC = () => {
  const { usermaven } = useUsermaven();
  usePageView(usermaven);

  const { user, isReceiverUser } = useSelector((state: ApplicationState) => {
    return {
      user: state.user.user,
      isReceiverUser: state.user.user && isReceiver(state.user.user),
    };
  });

  React.useEffect(() => {
    if (user && usermaven) {
      usermaven.id({
        id: user.id.toString(),
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,

        company: {
          id: user.parent_id?.toString() || `r${user.receiver_id}`,
          name: user.account_name,
          created_at: '',
          custom: {
            account_type: isReceiverUser ? 'Receiver' : 'Brand',
          },
        },
      });
    }
  }, [isReceiverUser, user, usermaven]);

  return null;
};

export default UsermavenTracking;
