import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';

export function fetchItemPackages({
  itemIds,
  filterId,
}: {
  itemIds?: number[];
  filterId?: number;
}) {
  const params = utils.createURL([
    { name: 'item_ids', values: itemIds },
    { name: 'filter_id', values: filterId },
  ]);
  return {
    type: 'FETCH_ITEM_PACKAGES',
    payload: axios.post(`${getApiRoot()}/v2/items/packages${params}`),
  };
}

export function fetchBrandUsedPackageFields(brandId: number) {
  const params = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_BRAND_USED_PACKAGES',
    payload: axios.get(`${getApiRoot()}/v2/items/packages/used_fields${params}`),
  };
}

export function fetchDimensionalWeight(
  length: string,
  width: string,
  height: string,
  dimensionsUomId: number,
  weightUomId: number
) {
  const params = utils.createURL([
    { name: 'height', values: height },
    { name: 'width', values: width },
    { name: 'length', values: length },
    { name: 'dimensions_uom_id', values: dimensionsUomId },
    { name: 'weight_uom_id', values: weightUomId },
  ]);
  return {
    type: 'FETCH_DIMENSSIONAL_WEIGHT',
    payload: axios.get(`${getApiRoot()}/v2/items/packages/dimensional_weight${params}`),
  };
}
