import { createSelector } from 'reselect';
import resourcesMapping from '../../constants/ApplicationResourcesMapping.json';
import { filterBySegment } from '../item_analysis/itemAnalysisSelector';
import {
  convertConfigNameToIds,
  convertConfigIdNameToName,
  convertConfigIdsNameToName,
  convertConfigNameToId,
} from '../../utils/ApplicationUtils';

const getApplications = state => state.items.application.applications;
const getBaseItemApplications = state => state.items.application.baseItemApplications;
const getOeItemApplications = state => state.items.application.oeApplications;
const getRankedApplications = state => state.items.application.rankedApplications;
const getRankedBaseApplication = state => state.items.application.rankedBaseApplication;
const getSelectedApplicationId = state => state.items.application.selectedApplicationId;
const getAccountVehicleResources = state => state.resources.applicationVehicle;
const getApplicationStructure = state => state.resources.data.vehicle_structure;
const sortRecommendations = state => state.exporter.recomendedVehicles;

const getSortedList = (values, orderIds) => {
  const nonRecomendedVehicles = values.filter(v => !orderIds.includes(v.id));
  const recommendedList = [];
  orderIds.forEach(id => {
    const foundValue = values.find(v => v.id === id);
    if (foundValue) recommendedList.push(foundValue);
  });

  return [
    ...recommendedList.sort((a, b) => {
      if (a.name < b.name) return -1;
      if (a.name > b.name) return 1;
      return 0;
    }),
    ...nonRecomendedVehicles,
  ];
};

export const getVehicleResources = createSelector(
  [getAccountVehicleResources],
  vehicleResources => {
    if (Object.keys(vehicleResources).length === 0) return vehicleResources;

    return {
      ...vehicleResources,
      models: vehicleResources.models.map(model =>
        model.duplicate_model > 0
          ? { ...model, extended_name: `${model.name} (${model.vehicle_type})` }
          : model
      ),
    };
  }
);

export const getGapCoverageVehicleResources = createSelector(
  [getAccountVehicleResources, sortRecommendations],
  (vehicleResources, recomendedVehicles) => {
    if (Object.keys(vehicleResources).length === 0) return vehicleResources;
    const allModels = vehicleResources.models.map(model =>
      model.duplicate_model > 0
        ? { ...model, extended_name: `${model.name} (${model.vehicle_type})` }
        : model
    );

    return {
      makes: getSortedList(vehicleResources.makes, recomendedVehicles.make_ids || []),
      models: recomendedVehicles.model_ids
        ? allModels.filter(model => recomendedVehicles.model_ids.includes(model.id))
        : allModels,
      years: recomendedVehicles.year_ids
        ? vehicleResources.years.filter(year => recomendedVehicles.year_ids.includes(year.id))
        : vehicleResources.years,
      model_type_groups: getSortedList(
        vehicleResources.model_type_groups,
        recomendedVehicles.vehicle_group_ids || []
      ),
      model_types: getSortedList(
        vehicleResources.model_types,
        recomendedVehicles.vehicle_type_ids || []
      ),
    };
  }
);

export const getValidResources = createSelector(
  [getRankedApplications, getRankedBaseApplication, getAccountVehicleResources],
  (rankedApplications, rankedBaseApplication, resources) => {
    if (!rankedApplications || !rankedBaseApplication) return null;

    const keys = [
      ...new Set([
        ...Object.keys(rankedApplications || {}),
        ...Object.keys(rankedBaseApplication || {}),
      ]),
    ];

    const mergedRanks = {};

    keys.forEach(key => {
      const rankValues = rankedApplications[key] || [];
      const baseRankValues = rankedBaseApplication[key] || [];
      const resourcesKey = resourcesMapping.hasOwnProperty(key) ? resourcesMapping[key] : key;

      // quickfix check for better mfr conflict solution
      mergedRanks[key === 'engine_mfrs' ? 'engine_mfrs' : resourcesKey] = [
        ...rankValues,
        ...baseRankValues,
      ]
        .filter((value, i, a) => a.findIndex(v => v.id === value.id) === i)
        .map(v => resources[resourcesKey].find(r => r.id === v.id) || { ...v, name: v.id });
    });

    return mergedRanks;
  }
);

export const applicationAnalysesGroups = createSelector(
  [state => filterBySegment(state)],
  filteredAnalyses => {
    const analysisGroups = {};
    // check for inconsistent subconfigs
    filteredAnalyses
      .filter(analysis => analysis.type_id === 106 && analysis.details)
      .forEach(analysis => {
        if (analysisGroups.hasOwnProperty(analysis.details.key)) {
          analysisGroups[analysis.details.key].push(analysis.reference_id);
        } else {
          analysisGroups[analysis.details.key] = [analysis.reference_id];
        }
      });
    // check for duplicates
    filteredAnalyses
      .filter(analysis => analysis.type_id === 112 && analysis.details)
      .forEach(analysis => {
        const referenceIds = Object.values(analysisGroups).reduce((a, b) => a.concat(b), []);

        if (!referenceIds.includes(analysis.reference_id)) {
          if (analysisGroups.hasOwnProperty(analysis.details.key)) {
            analysisGroups[analysis.details.key].push(analysis.reference_id);
          } else {
            analysisGroups[analysis.details.key] = [analysis.reference_id];
          }
        }
      });
    return analysisGroups;
  }
);

export const invalidVehicleSubconfigs = createSelector(
  [state => filterBySegment(state), getSelectedApplicationId],
  (filteredAnalyses, selectedApplicationId) => {
    return filteredAnalyses
      .filter(
        analysis =>
          analysis.type_id === 209 &&
          analysis.details &&
          analysis.reference_id === selectedApplicationId
      )
      .map(a => {
        const keys = Object.keys(a.details_json);
        const validKey = keys.find(k => k.startsWith('valid_'));
        const invalidKey = keys.find(k => k.startsWith('invalid_'));

        return {
          subConfig: convertConfigIdNameToName(a.details),
          invalidId: a.details_json[invalidKey][0],
          validIds: a.details_json[validKey],
        };
      });
  }
);

const getValues = (name, ids, resources) => {
  const configName = resourcesMapping.hasOwnProperty(name) ? resourcesMapping[name] : name;
  if (resources[configName]) {
    return resources[configName].filter(value => ids.includes(value.id));
  }
  return [];
};

// configs are fully included (not just id arrays)
const fullConfigs = ['mfr_label', 'qualifiers', 'notes', 'category', 'digital_assets'];

const groupApplicationConfigs = (application, structure, resources) => {
  const structuredApplication = { id: application.item_application_id };
  const applicationConfigs = Object.keys(application);
  if (application.qty) structuredApplication.qty = application.qty;
  structure.forEach(key => {
    if (typeof key === 'string') {
      const idKey = convertConfigNameToIds(key);
      if (
        application.hasOwnProperty(idKey) ||
        (application.hasOwnProperty(key) && fullConfigs.includes(key))
      ) {
        structuredApplication[key] = fullConfigs.includes(key)
          ? application[key]
          : getValues(key, application[idKey], resources);
      }
    } else if (Object.keys(key)[0] === 'engines') {
      // check if application has engine config
      if (applicationConfigs.find(key => key.startsWith('engine_'))) {
        structuredApplication.engines = {};
        const engineStructure = key.engines[0];
        Object.keys(engineStructure).forEach(groupKey => {
          if (
            engineStructure[groupKey].find(config =>
              applicationConfigs.includes(convertConfigNameToIds(config))
            )
          ) {
            structuredApplication.engines[groupKey] = {};
            engineStructure[groupKey].forEach(engineConfig => {
              const idKey = convertConfigNameToIds(engineConfig);

              if (application.hasOwnProperty(idKey)) {
                const values = getValues(engineConfig, application[idKey], resources);
                structuredApplication.engines[groupKey][engineConfig] = values;
              }
            });
          }
        });
      }
    } else {
      const group = Object.keys(key)[0];
      const configs = key[group];
      // check if application has configs of this group
      if (configs.find(config => applicationConfigs.includes(convertConfigNameToIds(config)))) {
        structuredApplication[group] = {};
        configs.forEach(config => {
          if (config === 'production_years' || config === 'years') {
            structuredApplication[group].years = application.years;
          } else {
            const idKey = convertConfigNameToIds(config);

            if (application.hasOwnProperty(idKey)) {
              structuredApplication[group][config] = getValues(
                config,
                application[idKey],
                resources
              );
            }
          }
        });
      }
    }
  });
  return structuredApplication;
};

export const structuredApplications = createSelector(
  [getApplications, getAccountVehicleResources, getApplicationStructure],
  (applications, resources, structure) =>
    applications.map(application => groupApplicationConfigs(application, structure, resources))
);

export const structuredLinkedApplications = createSelector(
  [getBaseItemApplications, getAccountVehicleResources, getApplicationStructure],
  (baseItemApplications, resources, structure) =>
    baseItemApplications.map(baseItemApplication => ({
      ...baseItemApplication,
      applications: baseItemApplication.applications.map(application =>
        groupApplicationConfigs(application, structure, resources)
      ),
    }))
);

export const structuredOeApplications = createSelector(
  [getOeItemApplications, getAccountVehicleResources, getApplicationStructure],
  (oeItemApplications, resources, structure) =>
    oeItemApplications.map(oeItemApplication => ({
      ...oeItemApplication,
      applications: oeItemApplication.applications.map(application =>
        groupApplicationConfigs(application, structure, resources)
      ),
    }))
);

export const mergeApplicationRanks = (
  selectedApplication,
  rankedApplications,
  resources,
  invalidConfigs
) => {
  if (!rankedApplications) rankedApplications = {};
  const mergedApplication = { id: selectedApplication.item_application_id };
  if (selectedApplication.mfr_label) mergedApplication.mfr_label = selectedApplication.mfr_label;
  if (selectedApplication.digital_assets)
    mergedApplication.digital_assets = selectedApplication.digital_assets;
  // if (selectedApplication.year_ranges)
  //   mergedApplication.year_ranges = selectedApplication.year_ranges;
  // get keys from application and rankedApplication
  const excludedApplicationKeys = [
    'item_application_id',
    'item_id',
    'default_overwrite',
    'item_category_id',
    'part_number',
    'year_ranges',
    'year_from',
    'year_to',
    'mfr_label',
    'notes',
    'qty',
    'digital_assets',
    'qualifiers',
    'qualifiers_readable',
    'category',
    'category_id',
  ];
  const applicationKeys = Object.keys(selectedApplication)
    .filter(key => !excludedApplicationKeys.includes(key))
    .map(key => convertConfigIdsNameToName(key));
  const configKeys = [...new Set([...Object.keys(rankedApplications), ...applicationKeys])];

  configKeys.forEach(key => {
    let mergedValues = [];
    const rankedValues = rankedApplications[key] || [];
    const mappedValueIds = selectedApplication[convertConfigNameToIds(key)] || [];
    const invalidConfig = invalidConfigs && invalidConfigs.find(c => c.subConfig === key);

    let values = rankedValues;
    if (mappedValueIds && Array.isArray(mappedValueIds)) {
      const keys = [...new Set([...rankedValues.map(value => value.id), ...mappedValueIds])];
      if (keys.length > rankedValues.length) {
        values = [...rankedValues];
        // add values which are not included in ranking (new mapped values)
        mappedValueIds.forEach(id => {
          if (!rankedValues.find(value => value.id === id)) values.push({ id });
        });
      }
    }

    const resourcesKey = resourcesMapping.hasOwnProperty(key) ? resourcesMapping[key] : key;
    values.forEach(value => {
      const mapped = mappedValueIds && mappedValueIds.includes(value.id);
      const invalid = invalidConfig && value.id === invalidConfig.invalidId;
      if (resources[resourcesKey]) {
        const valueResources = resources[resourcesKey].find(resource => resource.id === value.id);
        mergedValues.push({ ...value, ...valueResources, mapped, invalid });
      }
    });
    // some custom years can be missing in resources and we need to add name
    if (key === 'years')
      mergedValues = mergedValues
        .map(year => (year.name ? year : { ...year, name: year.id }))
        .sort((a, b) => (a.name > b.name ? 1 : -1));

    mergedApplication[key] = mergedValues;
  });

  return mergedApplication;
};

export const rankedApplications = createSelector(
  [getApplications, getRankedApplications, getSelectedApplicationId, getAccountVehicleResources],
  (applications, rankedApplications, selectedApplicationId, resources) => {
    // merge object containing ranked information with the object containing the mapped information
    if (!selectedApplicationId) return [];
    const selectedApplication = applications.find(
      application => application.item_application_id === selectedApplicationId
    );
    if (!selectedApplication) return [];

    return mergeApplicationRanks(selectedApplication, rankedApplications, resources);
  }
);

export const mapConfigIdToName = (configs, resources) => {
  const mappedConfigs = {};

  Object.keys(configs).forEach(groupKey => {
    const groupConfigs = configs[groupKey];
    const groupConfig = groupConfigs[0];
    const newConfig = {};

    Object.keys(groupConfig).forEach(key => {
      if (key === 'base_model_type_id') newConfig[key] = groupConfig.base_model_type_id;
      else {
        const name = convertConfigIdsNameToName(key);

        const resourcesKey = resourcesMapping.hasOwnProperty(name) ? resourcesMapping[name] : name;

        const values = groupConfig[key].map(id => {
          const valueResources = resources[resourcesKey].find(resource => resource.id === id);
          return valueResources.name;
        });

        newConfig[convertConfigNameToId(name)] = values[0];
      }
    });

    mappedConfigs[groupKey] = [newConfig];
  });

  return mappedConfigs;
};
