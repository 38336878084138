import { Col, Row, Input } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FormInput from '../../global/Forms/FormInput';
import FormRadio from '../../global/Forms/FormRadio';
import { DeliveryType } from '../../../utils/ChannelUtils';
import { ExtendedResources } from '../../../../types/resources';

type DeliveryFormPartProps = {
  disabled: boolean;
  values: any;
  deliveryTypes: ExtendedResources[];
  ftpDirectoryTypes: ExtendedResources[];
  protocolTypes: ExtendedResources[];
};

const DeliveryFormPart: React.FC<DeliveryFormPartProps> = ({
  disabled,
  values,
  deliveryTypes,
  ftpDirectoryTypes,
  // ftpModes,
  // encryptionTypes,
  protocolTypes,
}) => {
  const { t } = useTranslation();

  const protocolTypeName =
    protocolTypes.find(type => type.id === values.delivery.protocolTypeId)?.code?.toLowerCase() ||
    '';

  return (
    <React.Fragment>
      <Row gutter={20}>
        <Col span={10}>
          <FormRadio
            name="delivery.deliveryTypeId"
            values={deliveryTypes.map(type => {
              const frequencyId = values.schedule
                ? values.schedule.deliveryFrequencyId
                : values.deliveryFrequencyId;
              return type.code === 'manual' && frequencyId !== 4
                ? { ...type, disabled: true }
                : type;
            })}
            label={t('channel:deliveryMethod')}
            disabled={disabled}
            required
          />
        </Col>
      </Row>
      {values.delivery.deliveryTypeId === DeliveryType.FTP && (
        <React.Fragment>
          <Row gutter={20}>
            <Col span={8}>
              <FormInput
                addonBefore={values.delivery.protocolTypeId && `${protocolTypeName}://`}
                name="delivery.server"
                label={t('channel:server')}
                placeholder={t('channel:sampleserver')}
                required
                disabled={disabled}
                testId="server"
              />
            </Col>
            <Col span={7}>
              <FormInput
                name="delivery.user"
                label={t('channel:user')}
                placeholder={t('channel:username')}
                required
                disabled={disabled}
              />
            </Col>
            <Col span={7}>
              <FormInput
                name="delivery.password"
                label={t('channel:password')}
                placeholder={t('channel:password')}
                required
                disabled={disabled}
                type="password"
              />
            </Col>
            <Col span={2}>
              <FormInput
                name="delivery.port"
                label={t('channel:port')}
                placeholder={t('channel:sampleport')}
                disabled={disabled}
              />
            </Col>
          </Row>
          <div className="flex">
            <FormRadio
              name="delivery.ftpDirectoryTypeId"
              values={ftpDirectoryTypes}
              label={t('channel:folder')}
              required
              disabled={disabled}
            />
            <div className="flex flex-1 items-end">
              {values.delivery.ftpDirectoryTypeId === 1 && (
                <Input
                  className="flex-1 channel__ftp-path"
                  value={`${protocolTypeName}://${values.delivery.server || ''}`}
                  disabled
                />
              )}
              {values.delivery.ftpDirectoryTypeId === 2 && (
                <Input
                  className="flex-1 channel__ftp-path"
                  value={`${protocolTypeName}://${values.delivery.server || ''}/<brand>`}
                  disabled
                />
              )}
              {values.delivery.ftpDirectoryTypeId === 3 && (
                <FormInput
                  className="flex-1 channel__ftp-folder"
                  name="delivery.customDirectory"
                  placeholder={t('channel:sampleFolder')}
                  disabled={disabled || values.delivery.ftpDirectoryTypeId !== 3}
                  addonBefore={`${protocolTypeName}://${values.delivery.server || ''}/`}
                />
              )}
            </div>
          </div>
          <Row gutter={20}>
            {/* <Col span={5}>
            <FormRadio
              name="delivery.ftpModeId"
              values={ftpModes}
              label={t('channel:mode')}
              required
              disabled={disabled}
            />
          </Col> */}
            <Col span={7}>
              <FormRadio
                name="delivery.protocolTypeId"
                values={protocolTypes}
                label={t('channel:protocol')}
                required
                disabled={disabled}
              />
            </Col>
            {/* <Col span={10}>
            <FormRadio
              name="delivery.ftpEncryptionTypeId"
              values={encryptionTypes}
              label={t('channel:encryption')}
              required
              disabled={disabled}
            />
          </Col> */}
          </Row>
        </React.Fragment>
      )}
      {values.delivery.deliveryTypeId === DeliveryType.EMAIL && (
        <Row gutter={20}>
          <Col span={8}>
            <FormInput
              name="delivery.email"
              label={t('channel:email')}
              placeholder={t('channel:email')}
              required
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default DeliveryFormPart;
