import { Reducer } from 'redux';
import { FluxStandardAction } from 'redux-promise-middleware';
import { uniqBy as _uniqBy } from 'lodash';
import {
  Application,
  BaseItemApplications,
  PartTypeCategory,
  RankedApplications,
  Makes,
  Models,
  Years,
  Submodels,
  Regions,
  RankedPositions,
  Qualifier,
  BundleItem,
  ListQualifier,
  BundleRankedPostion,
  ApplicationNote,
  VcdbVersion,
  AppOrder,
} from '../../../types/application';
import { Item } from '../../../types/item';
import { FilterType } from '../../../types/filter';
import { Analysis } from '../../../types/analyses';
import { ApplicationElement } from '../../../types/all_application_validation';

export type ApplicationState = {
  readonly fetchingApplications: boolean;
  readonly fetchingDefaultQualifiers: boolean;
  readonly fetchingApplicationQualifiers: boolean;
  readonly fetchingDefaultNotes: boolean;
  readonly fetchingQualifiers: boolean;
  readonly fetchingMakes: boolean;
  readonly fetchingModels: boolean;
  readonly fetchingYears: boolean;
  readonly fetchingSubmodels: boolean;
  readonly fetchingRegions: boolean;
  readonly fetchingBundles: boolean;
  readonly fetchingGridRanks: boolean;
  readonly fetching: boolean;
  readonly updatingApplication: boolean;
  readonly applications: Application[];
  readonly baseItemApplications: BaseItemApplications[];
  readonly oeApplications: BaseItemApplications[];
  readonly applicationOrder: AppOrder[];
  readonly fetchingCategories: boolean;
  readonly partTypeCategories: PartTypeCategory[];
  readonly rankedApplications: RankedApplications | null;
  readonly gridRankedApplications: RankedApplications | null;
  readonly rankedBaseApplication: RankedApplications | null;
  readonly validVcdbVersions: number[];
  readonly getRecommendations: boolean;
  readonly selectedApplicationId: number | null;
  readonly makes: Makes[];
  readonly models: Models[];
  readonly years: Years[];
  readonly submodels: Submodels[];
  readonly regions: Regions[];
  readonly vcdbMakes: Makes[];
  readonly vcdbModels: Models[];
  readonly vcdbYears: Years[];
  readonly vcdbSubmodels: Submodels[];
  readonly vcdbRegions: Regions[];
  readonly equipmentYears: Years[];
  readonly rankedPositions: RankedPositions[];
  readonly childBundlePositions: BundleRankedPostion[];
  readonly qualifiers: ListQualifier[];
  readonly defaultQualifiers: Qualifier[];
  readonly defaultNotes: ApplicationNote[];
  readonly applicationQualifiers: Qualifier[];
  readonly applicationDigitalAssets: any[];
  readonly selectedApplicationDigitalAssetId?: number;
  readonly selectedVcdbs?: number[];
  readonly applicationParentItems: Item[];
  readonly applicationChildItems: Item[];
  readonly applicationBundleItems: BundleItem[];
  readonly applicationChildItemIds: number[];
  readonly parentApplicationItemIds: number[];
  readonly parentApplicationItemBases: BundleItem[];
  readonly listFilter?: FilterType[];
  readonly vcdbVersions: VcdbVersion[];
  readonly fetchingApplicationRows: boolean;
  readonly fetchingApplicationGridRows: boolean;
  readonly fetchListingError: boolean;
  readonly applicationRows: ApplicationElement[];
  readonly applicationGridRows: ApplicationElement[];
  readonly applicationGridGroupData: ApplicationElement[];
  readonly applicationGridGroupTotalCount: number;
  readonly columns: Array<{ [key: string]: string[] } | string>;
  readonly listAnalyses: Analysis[];
  readonly showApplicationListView: boolean;
  readonly showApplicationGridView: boolean;
  readonly fetchingVcdbVersions: boolean;
};

const initialState = {
  fetchingApplications: false,
  fetchingDefaultQualifiers: false,
  fetchingApplicationQualifiers: false,
  fetchingDefaultNotes: false,
  fetchingQualifiers: false,
  fetchingMakes: false,
  fetchingModels: false,
  fetchingYears: false,
  fetchingSubmodels: false,
  fetchingRegions: false,
  fetchingBundles: false,
  fetchingGridRanks: false,
  fetching: false,
  updatingApplication: false,
  applications: [],
  baseItemApplications: [],
  oeApplications: [],
  applicationOrder: [],
  fetchingCategories: false,
  partTypeCategories: [],
  rankedApplications: null,
  gridRankedApplications: null,
  rankedBaseApplication: null,
  validVcdbVersions: [],
  getRecommendations: false,
  selectedApplicationId: null,
  makes: [],
  models: [],
  years: [],
  submodels: [],
  regions: [],
  vcdbMakes: [],
  vcdbModels: [],
  vcdbYears: [],
  vcdbSubmodels: [],
  vcdbRegions: [],
  equipmentYears: [],
  rankedPositions: [],
  childBundlePositions: [],
  qualifiers: [],
  defaultQualifiers: [],
  defaultNotes: [],
  applicationQualifiers: [],
  applicationDigitalAssets: [],
  selectedApplicationDigitalAssetId: undefined,
  selectedVcdbs: undefined,
  applicationParentItems: [],
  applicationChildItems: [],
  applicationBundleItems: [],
  applicationChildItemIds: [],
  parentApplicationItemIds: [],
  parentApplicationItemBases: [],
  listFilter: undefined,
  vcdbVersions: [],
  fetchingApplicationRows: false,
  fetchingApplicationGridRows: false,
  fetchListingError: false,
  applicationRows: [],
  applicationGridRows: [],
  applicationGridGroupData: [],
  applicationGridGroupTotalCount: 0,
  columns: [],
  listAnalyses: [],
  showApplicationListView: false,
  showApplicationGridView: false,
  fetchingVcdbVersions: false,
};

const reducer: Reducer<ApplicationState, FluxStandardAction> = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_ITEM_APPLICATIONS_PENDING': {
      const { itemChanged, page } = action.meta;
      const reset = itemChanged || page === 1;
      return {
        ...state,
        fetchingApplications: true,
        applications: reset ? [] : state.applications,
        baseItemApplications: reset ? [] : state.baseItemApplications,
        oeApplications: reset ? [] : state.oeApplications,
        applicationOrder: itemChanged ? [] : state.applicationOrder,
      };
    }
    case 'FETCH_ITEM_APPLICATIONS_REJECTED': {
      return { ...state, fetchingApplications: false };
    }
    case 'FETCH_ITEM_APPLICATIONS_FULFILLED': {
      const { data } = action.payload;
      const add = action.meta.page > 1;
      const extendedBaseItemApplications = state.baseItemApplications.map(baseItem => {
        const item = data.base_items.find(
          (item: BaseItemApplications) => item.item_id === baseItem.item_id
        );
        return item
          ? { ...baseItem, applications: [...baseItem.applications, ...item.applications] }
          : baseItem;
      });
      const newBaseItemApplications = data.base_items.filter(
        (item: BaseItemApplications) =>
          !state.baseItemApplications.find(i => i.item_id === item.item_id)
      );
      const extendedOeApplications = state.oeApplications.map(baseItem => {
        const item = data.base_items.find(
          (item: BaseItemApplications) => item.item_id === baseItem.item_id
        );
        return item
          ? { ...baseItem, applications: [...baseItem.applications, ...item.applications] }
          : baseItem;
      });
      const newOeApplications = (data.oe_items || []).filter(
        (item: BaseItemApplications) =>
          !state.baseItemApplications.find(i => i.item_id === item.item_id)
      );

      return {
        ...state,
        fetchingApplications: false,
        applications: add ? [...state.applications, ...data.applications] : data.applications,
        baseItemApplications: [...extendedBaseItemApplications, ...newBaseItemApplications],
        oeApplications: [...extendedOeApplications, ...newOeApplications],
        // application bundle ids should be static, we store the initial order when filtering by key
        applicationOrder: !action.meta.keywords ? data.applications_order : state.applicationOrder,
      };
    }
    case 'FETCH_APPLICATION_PENDING': {
      return { ...state, fetchingApplications: true, applications: [] };
    }
    case 'FETCH_APPLICATION_REJECTED': {
      return { ...state, fetchingApplications: false };
    }
    case 'FETCH_APPLICATION_FULFILLED': {
      return {
        ...state,
        fetchingApplications: false,
        applications: action.payload.data.applications,
      };
    }
    case 'FETCH_APPLICATION_MAKES_MODELS_YEARS_PENDING': {
      return { ...state, fetchingMakes: true, fetchingModels: true, fetchingYears: true };
    }
    case 'FETCH_APPLICATION_MAKES_MODELS_YEARS_REJECTED': {
      return { ...state, fetchingMakes: false, fetchingModels: false, fetchingYears: false };
    }
    case 'FETCH_APPLICATION_MAKES_MODELS_YEARS_FULFILLED': {
      return {
        ...state,
        fetchingMakes: false,
        fetchingModels: false,
        fetchingYears: false,
        makes: action.payload.data.makes || [],
        models: action.payload.data.models || [],
        years: action.payload.data.years || [],
      };
    }
    case 'FETCH_APPLICATION_MAKES_PENDING': {
      return { ...state, fetchingMakes: true, makes: [], models: [] };
    }
    case 'FETCH_APPLICATION_MAKES_REJECTED': {
      return { ...state, fetchingMakes: false };
    }
    case 'FETCH_APPLICATION_MAKES_FULFILLED': {
      return { ...state, fetchingMakes: false, makes: action.payload.data };
    }
    case 'FETCH_APPLICATION_MODELS_PENDING': {
      return { ...state, fetchingModels: true, models: [] };
    }
    case 'FETCH_APPLICATION_MODELS_REJECTED': {
      return { ...state, fetchingModels: false };
    }
    case 'FETCH_APPLICATION_MODELS_FULFILLED': {
      return { ...state, fetchingModels: false, models: action.payload.data };
    }
    case 'FETCH_APPLICATION_SUBMODELS_PENDING': {
      return { ...state, fetchingSubmodels: true, submodels: [] };
    }
    case 'FETCH_APPLICATION_SUBMODELS_REJECTED': {
      return { ...state, fetchingSubmodels: false };
    }
    case 'FETCH_APPLICATION_SUBMODELS_FULFILLED': {
      return {
        ...state,
        fetchingSubmodels: false,
        submodels: action.payload.data,
      };
    }
    case 'FETCH_APPLICATION_REGIONS_PENDING': {
      return { ...state, fetchingRegions: true, regions: [] };
    }
    case 'FETCH_APPLICATION_REGIONS_REJECTED': {
      return { ...state, fetchingRegions: false };
    }
    case 'FETCH_APPLICATION_REGIONS_FULFILLED': {
      return {
        ...state,
        fetchingRegions: false,
        regions: action.payload.data,
      };
    }
    case 'FETCH_APPLICATION_YEARS_PENDING': {
      return { ...state, fetchingYears: true, years: [] };
    }
    case 'FETCH_APPLICATION_YEARS_REJECTED': {
      return { ...state, fetchingYears: false };
    }
    case 'FETCH_APPLICATION_YEARS_FULFILLED': {
      return { ...state, fetchingYears: false, years: action.payload.data };
    }
    case 'FETCH_EQUIPMENT_YEARS_PENDING': {
      return { ...state, fetchingYears: true, equipmentYears: [] };
    }
    case 'FETCH_EQUIPMENT_YEARS_REJECTED': {
      return { ...state, fetchingYears: false };
    }
    case 'FETCH_EQUIPMENT_YEARS_FULFILLED': {
      return { ...state, fetchingYears: false, equipmentYears: action.payload.data };
    }
    case 'FETCH_VCDB_MAKES_PENDING': {
      return { ...state, fetchingMakes: true, vcdbMakes: [], vcdbModels: [] };
    }
    case 'FETCH_VCDB_MAKES_REJECTED': {
      return { ...state, fetchingMakes: false };
    }
    case 'FETCH_VCDB_MAKES_FULFILLED': {
      return { ...state, fetchingMakes: false, vcdbMakes: action.payload.data };
    }
    case 'FETCH_VCDB_MODELS_PENDING': {
      return { ...state, fetchingModels: true, vcdbModels: [] };
    }
    case 'FETCH_VCDB_MODELS_REJECTED': {
      return { ...state, fetchingModels: false };
    }
    case 'FETCH_VCDB_MODELS_FULFILLED': {
      return { ...state, fetchingModels: false, vcdbModels: action.payload.data };
    }
    case 'FETCH_VCDB_SUBMODELS_PENDING': {
      return { ...state, fetchingSubmodels: true, vcdbSubmodels: [] };
    }
    case 'FETCH_VCDB_SUBMODELS_REJECTED': {
      return { ...state, fetchingSubmodels: false };
    }
    case 'FETCH_VCDB_SUBMODELS_FULFILLED': {
      return { ...state, fetchingSubmodels: false, vcdbSubmodels: action.payload.data };
    }
    case 'FETCH_VCDB_REGIONS_PENDING': {
      return { ...state, fetchingRegions: true, vcdbRegions: [] };
    }
    case 'FETCH_VCDB_REGIONS_REJECTED': {
      return { ...state, fetchingRegions: false };
    }
    case 'FETCH_VCDB_REGIONS_FULFILLED': {
      return { ...state, fetchingRegions: false, vcdbRegions: action.payload.data };
    }
    case 'FETCH_VCDB_YEARS_PENDING': {
      return { ...state, fetchingYears: true, vcdbYears: [] };
    }
    case 'FETCH_VCDB_YEARS_REJECTED': {
      return { ...state, fetchingYears: false };
    }
    case 'FETCH_VCDB_YEARS_FULFILLED': {
      return { ...state, fetchingYears: false, vcdbYears: action.payload.data };
    }
    case 'FETCH_RANKED_POSITIONS_FULFILLED': {
      return { ...state, rankedPositions: action.payload.data };
    }
    case 'FETCH_BUNDLE_POSITIONS_FULFILLED': {
      const newValue = { [action.meta.categoryId]: action.payload.data };
      const childBundlePositions = { ...state.childBundlePositions, ...newValue };
      return { ...state, childBundlePositions };
    }
    case 'FETCH_APPLICATION_PART_TYPE_CATEGORIES_PENDING': {
      const partTypeCategories = action.meta.page === 1 ? [] : state.partTypeCategories;
      return { ...state, partTypeCategories, fetchingCategories: true };
    }
    case 'FETCH_APPLICATION_PART_TYPE_CATEGORIES_FULFILLED': {
      const newCategories =
        action.meta.page > 1
          ? [...state.partTypeCategories, ...action.payload.data[3]]
          : action.payload.data[3];
      return { ...state, fetchingCategories: false, partTypeCategories: newCategories };
    }
    case 'FETCH_APPLICATION_DIGITAL_ASSETS_FULFILLED': {
      const digitalAssets = action.payload.data;
      return {
        ...state,
        applicationDigitalAssets: action.payload.data,
        selectedApplicationDigitalAssetId: digitalAssets.length ? digitalAssets[0].id : undefined,
      };
    }
    case 'FETCH_APPLICATION_LIST_FILTER_FULFILLED': {
      const listFilter = action.payload.data;
      return { ...state, listFilter };
    }
    case 'FETCH_ITEM_APPLICATION_LISTINGS_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        applicationRows: page === 1 ? [] : state.applicationRows,
        columns: state.columns,
        listAnalyses: page === 1 ? [] : state.listAnalyses,
        fetchingApplicationRows: true,
        fetchListingError: false,
      };
    }
    case 'FETCH_ITEM_APPLICATION_LISTINGS_REJECTED': {
      // eslint-disable-next-line no-underscore-dangle
      const canceled = !!action.payload.__CANCEL__;
      const error = !canceled;
      const fetching = canceled;
      return { ...state, fetchingApplicationRows: fetching, fetchListingError: error };
    }
    case 'FETCH_ITEM_APPLICATION_LISTINGS_FULFILLED': {
      const listing = action.payload.data;
      return {
        ...state,
        applicationRows: [...state.applicationRows, ...listing.applications],
        columns: listing.columns,
        listAnalyses: listing.analysis,
        fetchingApplicationRows: false,
        fetchListingError: false,
      };
    }
    case 'FETCH_ITEM_APPLICATION_GRIDS_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        applicationGridRows: page === 1 ? [] : state.applicationGridRows,
        columns: page === 1 ? [] : state.columns,
        listAnalyses: page === 1 ? [] : state.listAnalyses,
        fetchingApplicationGridRows: true,
      };
    }
    case 'FETCH_ITEM_APPLICATION_GRIDS_REJECTED': {
      // eslint-disable-next-line no-underscore-dangle
      const canceled = !!action.payload.__CANCEL__;
      const fetching = canceled;
      return { ...state, fetchingApplicationGridRows: fetching };
    }
    case 'FETCH_ITEM_APPLICATION_GRIDS_FULFILLED': {
      const listing = action.payload.data;
      return {
        ...state,
        applicationGridRows: [...state.applicationGridRows, ...listing.application_group],
        columns: listing.columns,
        listAnalyses: listing.analysis,
        fetchingApplicationGridRows: false,
      };
    }
    case 'FETCH_APPLICATION_GROUP_DATA_PENDING': {
      const { page } = action.meta;
      return {
        ...state,
        applicationGridGroupData: page === 1 ? [] : state.applicationGridGroupData,
        applicationGridGroupTotalCount: page === 1 ? [] : state.applicationGridGroupTotalCount,
      };
    }
    case 'FETCH_APPLICATION_GROUP_DATA_FULFILLED': {
      const groupApplications = action.payload.data.applications;
      const totalCount = action.payload.data.total_count;
      return {
        ...state,
        applicationGridGroupData: [...state.applicationGridGroupData, ...groupApplications],
        applicationGridGroupTotalCount: totalCount,
      };
    }
    case 'FETCH_VCDB_VERSIONS_PENDING': {
      return { ...state, selectedVcdbs: [], fetchingVcdbVersions: true };
    }
    case 'FETCH_VCDB_VERSIONS_FULFILLED': {
      const vcdbVersions = action.payload.data;
      return {
        ...state,
        vcdbVersions,
        fetchingVcdbVersions: false,
      };
    }
    case 'SET_APPLICATION_LIST_VIEW': {
      return {
        ...state,
        showApplicationListView: action.payload.showListView,
      };
    }
    case 'SET_APPLICATION_GRID_VIEW': {
      return {
        ...state,
        showApplicationGridView: action.payload.showGridView,
      };
    }
    case 'SELECT_APPLICATION': {
      return {
        ...state,
        selectedApplicationId: action.payload.applicationId,
        makes: [],
        models: [],
        years: [],
        submodels: [],
        regions: [],
        applicationQualifiers: [],
        rankedApplications: null,
        gridRankedApplications: null,
        validVcdbVersions: [],
        applicationDigitalAssets: [],
        selectedApplicationDigitalAssetId: undefined,
        getRecommendations: false,
      };
    }
    case 'SELECT_APPLICATION_DIGITAL_ASSET': {
      return { ...state, selectedApplicationDigitalAssetId: action.payload };
    }
    case 'SELECT_VCDBS': {
      return { ...state, selectedVcdbs: action.payload.ids };
    }
    case 'FETCH_RANKED_APPLICATIONS_PENDING': {
      return { ...state, fetching: true };
    }
    case 'FETCH_RANKED_APPLICATIONS_REJECTED': {
      return { ...state, fetching: false };
    }
    case 'FETCH_RANKED_APPLICATIONS_FULFILLED': {
      return {
        ...state,
        fetching: false,
        rankedApplications: action.payload.data.sub_configs,
        validVcdbVersions: action.payload.data.valid_versions,
      };
    }
    case 'FETCH_GRID_RANKED_APPLICATIONS_PENDING': {
      return { ...state, fetchingGridRanks: true };
    }
    case 'FETCH_GRID_RANKED_APPLICATIONS_REJECTED': {
      return { ...state, fetchingGridRanks: false };
    }
    case 'FETCH_GRID_RANKED_APPLICATIONS_FULFILLED': {
      return {
        ...state,
        fetchingGridRanks: false,
        gridRankedApplications: action.payload.data.sub_configs,
      };
    }
    case 'FETCH_VISIBLE_APPLICATION_RANKS_FULFILLED': {
      const ranked = state.fetching
        ? { ...state.rankedApplications, ...action.payload.data.sub_configs }
        : state.rankedApplications;
      return { ...state, rankedApplications: ranked };
    }
    case 'FETCH_RANKED_BASE_APPLICATIONS_FULFILLED': {
      return {
        ...state,
        rankedBaseApplication: action.payload.data.sub_configs,
      };
    }
    case 'FETCH_QUALIFIERS_PENDING': {
      const qualifiers = action.meta.page > 1 ? state.qualifiers : [];
      return { ...state, fetchingQualifiers: true, qualifiers };
    }
    case 'FETCH_QUALIFIERS_FULFILLED': {
      const data = action.payload.data;
      const qualifiers = action.meta.page > 1 ? [...state.qualifiers, ...data] : data;
      return { ...state, fetchingQualifiers: false, qualifiers };
    }
    case 'FETCH_APPLICATION_QUALIFIERS_PENDING': {
      return { ...state, fetchingApplicationQualifiers: true, applicationQualifiers: [] };
    }
    case 'FETCH_APPLICATION_QUALIFIERS_FULFILLED': {
      return {
        ...state,
        fetchingApplicationQualifiers: false,
        applicationQualifiers: action.payload.data,
      };
    }
    case 'FETCH_DEFAULT_QUALIFIERS_PENDING': {
      return {
        ...state,
        fetchingDefaultQualifiers: true,
        defaultQualifiers: [],
      };
    }
    case 'FETCH_DEFAULT_QUALIFIERS_FULFILLED': {
      return {
        ...state,
        fetchingDefaultQualifiers: false,
        defaultQualifiers: action.payload.data,
      };
    }
    case 'FETCH_DEFAULT_NOTES_PENDING': {
      return {
        ...state,
        fetchingDefaultNotes: true,
        defaultNotes: [],
      };
    }
    case 'FETCH_DEFAULT_NOTES_FULFILLED': {
      return {
        ...state,
        fetchingDefaultNotes: false,
        defaultNotes: action.payload.data,
      };
    }
    case 'CREATE_APPLICATIONS_FULFILLED': {
      const applications = [
        ...state.applications,
        {
          item_application_id: action.payload.data.id,
          ...(action.meta.categoryId && { item_category_id: action.meta.categoryId }),
          ...(action.meta.itemId && { item_id: action.meta.itemId }),
        } as Application,
      ];
      return {
        ...state,
        selectedApplicationId: action.payload.data.id,
        selectedSubconfig: 'vehicle_bases',
        rankedApplications: null,
        gridRankedApplications: null,
        applications,
        makes: [],
        models: [],
        years: [],
        submodels: [],
        regions: [],
        getRecommendations: false,
      };
    }
    case 'CREATE_APPLICATION_DIGITAL_ASSET_FULFILLED': {
      const newDigitalAsset = action.payload.data;
      const assetsIds = state.applicationDigitalAssets.map(asset => asset.id);
      const isOverwrite = assetsIds.includes(newDigitalAsset.id);
      return {
        ...state,
        applicationDigitalAssets: isOverwrite
          ? state.applicationDigitalAssets.map(asset => {
              if (asset.id === newDigitalAsset.id) {
                return newDigitalAsset;
              }
              return asset;
            })
          : [newDigitalAsset, ...state.applicationDigitalAssets],
        selectedApplicationDigitalAssetId: newDigitalAsset.id,
      };
    }
    case 'UPDATE_APPLICATION_PENDING': {
      const updatedApplication = action.meta.application;
      const applications = state.applications.map(application => {
        return application.item_application_id === updatedApplication.item_application_id
          ? updatedApplication
          : application;
      });
      return { ...state, applications, updatingApplication: true };
    }
    case 'UPDATE_APPLICATION_REJECTED': {
      return { ...state, updatingApplication: false };
    }
    case 'UPDATE_APPLICATION_FULFILLED': {
      return { ...state, updatingApplication: false };
    }
    case 'SET_RECOMMENDATIONS': {
      const getRecommendations = action.payload;
      return {
        ...state,
        getRecommendations,
        rankedApplications: getRecommendations ? state.rankedApplications : null,
      };
    }
    case 'UPDATE_APPLICATION_NOTES_PENDING': {
      const notes = action.meta.notes;
      const applications = state.applications.map(application =>
        application.id === action.meta.applicationId ? { ...application, notes } : application
      );
      return { ...state, applications };
    }
    case 'UPDATE_APPLICATION_QUALIFIERS_FULFILLED': {
      const qualifiers = action.payload.data.map((q: Qualifier) => ({
        text: q.qualifier_text,
        values: q.values,
      }));
      const applications = state.applications.map(application => {
        if (application.id === action.meta.applicationId) {
          return { ...application, qualifiers };
        }
        return application;
      });
      return { ...state, applicationQualifiers: action.payload.data, applications };
    }
    case 'UPDATE_DEFAULT_QUALIFIERS_FULFILLED': {
      return {
        ...state,
        defaultQualifiers: action.payload.data,
      };
    }
    case 'UPDATE_DEFAULT_NOTES_FULFILLED': {
      return {
        ...state,
        defaultNotes: action.payload.data,
      };
    }
    case 'LINK_APPLICATION_DIGITAL_ASSET_FULFILLED': {
      const digitalAssets = action.payload.data;
      return {
        ...state,
        applicationDigitalAssets: [digitalAssets, ...state.applicationDigitalAssets],
        selectedApplicationDigitalAssetId: digitalAssets.id,
      };
    }
    case 'UPDATE_APPLICATION_DIGITAL_ASSET_PENDING': {
      return {
        ...state,
        applicationDigitalAssets: state.applicationDigitalAssets.map(digitalAsset => {
          if (digitalAsset.id === action.meta.applicationDigitalAssetId) {
            const newValue = { [action.meta.key]: action.meta.value };
            return { ...digitalAsset, ...newValue };
          }
          return digitalAsset;
        }),
      };
    }
    case 'UPDATE_DIGITAL_ASSET_PARENT_PENDING': {
      return {
        ...state,
        applicationDigitalAssets: state.applicationDigitalAssets.map(digitalAsset => {
          if (digitalAsset.digital_asset_id === action.meta.digitalAssetId) {
            const newValue = { [action.meta.key]: action.meta.value };
            return { ...digitalAsset, ...newValue };
          }
          return digitalAsset;
        }),
      };
    }
    case 'DELETE_APPLICATION_FULFILLED': {
      const { id } = action.meta;
      const applications = state.applications.filter(
        application => application.item_application_id !== id
      );
      const applicationBundleItems = state.applicationBundleItems.map(bundle => {
        if (bundle.application_ids.includes(id))
          return {
            ...bundle,
            application_ids: bundle.application_ids.filter((bId: number) => bId !== id),
          };
        return bundle;
      });
      const applicationRows = state.applicationRows.filter(r => r.application_id !== id);
      return { ...state, applications, applicationBundleItems, applicationRows };
    }
    case 'DELETE_DEFAULT_QUALIFIERS_FULFILLED': {
      const defaultQualifiers = state.defaultQualifiers.filter(
        qualifier => !action.meta.qualifierIds.includes(qualifier.qualifier_id)
      );
      return { ...state, defaultQualifiers };
    }
    case 'DELETE_APPLICATION_DIGITAL_ASSETS_PENDING': {
      return {
        ...state,
        applicationDigitalAssets: state.applicationDigitalAssets.filter(
          digitalAsset => digitalAsset.id !== action.meta.assetId
        ),
      };
    }
    case 'FETCH_APPLICATION_PARENT_ITEMS_FULFILLED': {
      const linkedItems = state.applicationParentItems.filter(item =>
        action.meta.selectedIds.includes(item.id)
      );
      return {
        ...state,
        applicationParentItems: _uniqBy([...linkedItems, ...action.payload.data.items], 'id'),
      };
    }
    case 'FETCH_APPLICATION_LINKED_ITEMS_PENDING': {
      return { ...state, applicationChildItems: [], applicationChildItemIds: [] };
    }
    case 'FETCH_APPLICATION_LINKED_ITEMS_FULFILLED': {
      return { ...state, applicationChildItemIds: action.payload.data };
    }
    case 'FETCH_APPLICATION_BUNDLE_ITEMS_PENDING': {
      return { ...state, fetchingBundles: true, applicationBundleItems: [] };
    }
    case 'FETCH_APPLICATION_BUNDLE_ITEMS_REJECTED': {
      return { ...state, fetchingBundles: false };
    }
    case 'FETCH_APPLICATION_BUNDLE_ITEMS_FULFILLED': {
      return { ...state, fetchingBundles: false, applicationBundleItems: action.payload.data };
    }
    case 'FETCH_APPLICATION_CHILD_ITEMS_FULFILLED': {
      return { ...state, applicationChildItems: action.payload.data.items };
    }
    case 'CREATE_APPLICATION_LINKS_FULFILLED': {
      const parentApplicationItemBases = [
        ...state.parentApplicationItemBases,
        ...action.payload.data,
      ];
      const parentApplicationItemIds = parentApplicationItemBases.map((b: any) => b.base_item_id);

      return {
        ...state,
        parentApplicationItemIds,
        parentApplicationItemBases,
      };
    }
    case 'CREATE_VCDB_FULFILLED': {
      const vcdbs = action.payload.data;
      return { ...state, vcdbVersions: [...vcdbs, ...state.vcdbVersions] };
    }
    case 'REMOVE_APPLICATION_LINKS_PENDING': {
      const { baseItemIds } = action.meta;
      const parentApplicationItemIds = state.parentApplicationItemIds
        ? state.parentApplicationItemIds.filter(id => !baseItemIds.includes(id))
        : [];
      const baseItemApplications = state.baseItemApplications.filter(
        baseItemApplications => !baseItemIds.includes(baseItemApplications.item_id)
      );
      return {
        ...state,
        parentApplicationItemIds,
        applicationParentItems:
          parentApplicationItemIds.length === 0 ? [] : state.applicationParentItems,
        baseItemApplications,
      };
    }
    case 'FETCH_APPLICATION_LINKS_PENDING': {
      return {
        ...state,
        parentApplicationItemIds: [],
        applicationParentItems: [],
      };
    }
    case 'FETCH_APPLICATION_LINKS_FULFILLED': {
      const parentApplicationItemBases = action.payload.data;
      const parentApplicationItemIds = parentApplicationItemBases.map((b: any) => b.base_item_id);
      return {
        ...state,
        parentApplicationItemIds,
        parentApplicationItemBases,
      };
    }
    case 'UPDATE_APPLICATION_BUNDLE_FULFILLED': {
      return { ...state, applicationBundleItems: action.payload.data };
    }
    case 'REMOVE_APPLICATION_BUNDLE_FULFILLED': {
      const { itemBaseIds } = action.meta;
      const applicationBundleItems = state.applicationBundleItems.filter(
        bundle => !itemBaseIds.includes(bundle.id)
      );
      return { ...state, applicationBundleItems };
    }
    case 'UPDATE_LIST_FILTER_KEYWORDS_FULFILLED': {
      const keywords = action.meta.keywords;
      return {
        ...state,
        listFilter: { ...state.listFilter, keywords },
      };
    }
    case 'DELETE_VCDB_FULFILLED': {
      const { id } = action.meta;
      const vcdbVersions = state.vcdbVersions.filter(vcdb => vcdb.id !== id);
      return { ...state, vcdbVersions };
    }
  }
  return state;
};

export default reducer;
