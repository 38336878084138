import React from 'react';
import { Form, Radio } from 'antd';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ExportBuilderAdvancedTemplate } from '../../../../types/export_builder_advanced';
import FormInput from '../../global/Forms/FormInput';
import { AsyncDispatch } from '../../../../types/global';
import { updateExportBuilderAdvancedTemplate } from '../../../actions/brand/export_builder_advanced/update';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { fetchExportOptions } from '../../../actions/brand/export/fetch';

export const validationSchemaTemplate = Yup.object().shape({
  templateName: Yup.string().required('Required'),
  fileTypeId: Yup.number().required('Required'),
  csvSeparator: Yup.string().when(['fileTypeId'], (fileTypeId: number, schema: any) =>
    fileTypeId === 1 ? schema.required('Required') : schema
  ),
});

type ExportBuilderAddTemplateDrawerProps = {
  brandId?: number;
  visible: boolean;
  onClose: () => void;
  isNewTemplate: boolean;
  setNewTemplateState: (templateId: number) => void;
  selectedTemplate: ExportBuilderAdvancedTemplate | undefined;
  receiver: boolean;
};

const ExportBuilderAddTemplateDrawer: React.FC<ExportBuilderAddTemplateDrawerProps> = ({
  brandId,
  selectedTemplate,
  setNewTemplateState,
  isNewTemplate,
  onClose,
  visible,
  receiver,
}) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <DrawerFormik
      title={t('exportBuilderAdvanced:addTemplate')}
      width={480}
      visible={visible}
      initialValues={{
        templateName: selectedTemplate ? selectedTemplate.name : '',
        fileTypeId: selectedTemplate ? selectedTemplate.file_type_id : 2,
        csvSeparator: selectedTemplate ? selectedTemplate.csv_separator : '',
      }}
      validationSchema={validationSchemaTemplate}
      onClose={onClose}
      onSubmit={values => {
        const obj = {
          ...(selectedTemplate && { id: selectedTemplate.id }),
          csv_separator: values.csvSeparator,
          file_type_id: values.fileTypeId,
          name: values.templateName,
          parent_owner_brand_id: brandId,
        };
        dispatch(updateExportBuilderAdvancedTemplate(obj)).then(res => {
          if (isNewTemplate) {
            const id = res.value.data.id;
            setNewTemplateState(id);
            if (receiver) dispatch(fetchExportOptions('export'));
          }
          onClose();
        });
      }}
      enableReinitialize
    >
      {({ setFieldValue, values }) => (
        <Form layout="vertical">
          <FormInput
            name="templateName"
            label={t('common:name')}
            className="w-full"
            testId="add-template-name"
          />
          <div className="flex items-center">
            <Form.Item label="Export type">
              <div className="mr-4">
                <Radio.Group
                  onChange={e => setFieldValue('fileTypeId', e.target.value)}
                  value={values.fileTypeId}
                >
                  <Radio.Button value={2}>{t('glossary:excel')}</Radio.Button>
                  <Radio.Button value={1}>{t('glossary:csv')}</Radio.Button>
                </Radio.Group>
              </div>
            </Form.Item>
            {values.fileTypeId === 1 && (
              <FormInput name="csvSeparator" label={t('exportBuilderAdvanced:csvDelimiter')} />
            )}
          </div>
        </Form>
      )}
    </DrawerFormik>
  );
};

export default ExportBuilderAddTemplateDrawer;
