import React from 'react';
import { Form, Button, message, Modal, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormikValues, FieldArray, FormikProps } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { ApplicationState } from '../../../reducers';
import FormInput from '../../global/Forms/FormInput';
import FormSelect from '../../global/Forms/FormSelect';
import { updateCustomField, deleteCustomField } from '../../../actions/brand/custom_fields';
import { AsyncDispatch } from '../../../../types/global';
import {
  BrandCustomField,
  CustomFieldType,
  CustomFieldOption,
} from '../../../../types/custom_fields';
import SettingsCustomFieldOptions from './SettingsCustomFieldOptions';
import globalTranslations from '../../../constants/GlobalTranslation.json';
import { intercomEvent } from '../../../utils/IntercomUtils';
import { getSelectedBrandCode } from '../../../selectors/brand/brandSelector';
import FormSwitch from '../../global/Forms/FormSwitch';
import AntTooltip from '../../global/AntTooltip';

type SettingsCustomFieldEditDrawerProps = {
  visible: boolean;
  canEditSettingsCustomFields: boolean;
  closeDrawer: () => void;
  customFieldsLength: number;
  customField?: BrandCustomField;
  customFields: BrandCustomField[];
};

const SettingsCustomFieldEditDrawer: React.FC<SettingsCustomFieldEditDrawerProps> = ({
  visible,
  canEditSettingsCustomFields,
  closeDrawer,
  customFieldsLength,
  customField,
  customFields,
}) => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();
  const { customFieldTypes, brandId, brandCode, fetchingScripts, scripts } = useSelector(
    (state: ApplicationState) => {
      return {
        customFieldTypes: state.resources.data.custom_field?.types || [],
        brandId: state.parent.brands.selectedBrandId,
        brandCode: getSelectedBrandCode(state),
        fetchingScripts: state.distribution.apiSettings.fetchingScripts,
        scripts: state.distribution.apiSettings.scripts,
      };
    }
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('validation:required'))
      .test(
        'check-unique-name',
        t('settings:customFields.validation.customFieldIsUsed'),
        (val: any) => {
          return !customField || (customField && customField.name !== val)
            ? customFields.every(({ name }) => name !== val)
            : true;
        }
      ),
    customFieldTypeId: Yup.number().nullable().required(t('validation:required')),
    options: Yup.array().when(
      ['customFieldTypeId'],
      (customFieldTypeId: CustomFieldType, schema: any) =>
        customFieldTypeId === CustomFieldType.Dropdown
          ? Yup.array()
              .of(Yup.object().shape({ name: Yup.string().required() }))
              .test(
                'check-unique-option-name',
                t('settings:customFields.validation.dontUseDuplicateOptions'),
                array => array!.length === new Set(array!.map((a: any) => a.name)).size
              )
              .min(1, t('settings:customFields.validation.atLeastOneOptionIsRequired'))
          : schema
    ),
  });

  const drawerProps = {
    visible,
    width: 400,
    title: t('glossary:segments.customField_other'),
    validationSchema,
    destroyOnClose: true,
    initialValues: {
      name: customField ? customField.name : '',
      customFieldTypeId: customField ? customField.custom_field_type_id : null,
      options: customField && customField.options ? customField.options : [],
      publishChanges: customField ? !customField.ignore_on_sync : true,
      scriptId: customField && customField.script_id ? customField.script_id : null,
    },
    deleteButtonEnabled: canEditSettingsCustomFields,
    handleSaveButtonEnabled: (formik: FormikProps<FormikValues>) =>
      canEditSettingsCustomFields && formik.dirty,
    onSubmit: (values: FormikValues) => {
      dispatch(
        updateCustomField({
          customFieldId: customField ? customField.id : null,
          name: values.name,
          brandId,
          customFieldTypeId: values.customFieldTypeId,
          recordNumber: customField ? customField.record_number : customFieldsLength,
          options: values.customFieldTypeId === CustomFieldType.Dropdown ? values.options : null,
          ignoreOnSync: values.publishChanges ? 0 : 1,
          scriptId: values.customFieldTypeId === CustomFieldType.Button ? values.scriptId : null,
        })
      ).then(() => closeDrawer());
    },
    onDelete: customField
      ? () => {
          intercomEvent('viewed-brand-settings', {
            location: 'settings-custom-fields',
            brand_code: brandCode!,
            action: 'delete',
          });

          Modal.confirm({
            title: t('settings:customFields.confirmDelete'),
            okText: t('common:delete'),
            okButtonProps: { danger: true },
            onOk() {
              dispatch(deleteCustomField(customField.id))
                .then(() => closeDrawer())
                .catch(e => {
                  const error = e.response.data.message || globalTranslations.unknownError;
                  message.error(error);
                });
            },
          });
        }
      : undefined,
    onClose: closeDrawer,
  };

  return (
    <DrawerFormik {...drawerProps}>
      {({ values, handleSubmit, setFieldValue, errors, submitCount }) => (
        <Form layout="vertical" onFinish={() => handleSubmit()}>
          <FormInput name="name" label={t('common:name')} required />
          <FormSelect
            name="customFieldTypeId"
            label={t('common:type')}
            values={customFieldTypes}
            required
          />
          {values.customFieldTypeId === CustomFieldType.Dropdown && (
            <FieldArray
              name="options"
              render={arrayHelpers => (
                <div className="flex-col">
                  <Button
                    type="primary"
                    ghost
                    onClick={() =>
                      arrayHelpers.push({
                        name: '',
                        record_number: values.options.length + 1,
                      })
                    }
                  >
                    {t('settings:customFields.addNewOption')}
                  </Button>
                  {values.options.length > 0 && (
                    <SettingsCustomFieldOptions
                      options={values.options}
                      updateOptions={(options: CustomFieldOption[]) => {
                        setFieldValue('options', options);
                      }}
                    />
                  )}
                </div>
              )}
            />
          )}
          {values.customFieldTypeId === CustomFieldType.Button && (
            <FormSelect
              name="scriptId"
              label={t('api:script')}
              values={scripts}
              loading={fetchingScripts}
              required
            />
          )}

          <Typography.Title level={5} className="mt-5">
            {t('settings:customFields.publishSettings')}
          </Typography.Title>
          <div className="flex">
            <FormSwitch
              name="publishChanges"
              checkedChildren={t('common:yes')}
              unCheckedChildren={t('common:no')}
            />
            <span className="h-8 ml-3 leading-8">
              {t('settings:customFields.publishChanges')}
              <AntTooltip title={t('settings:customFields.publishChangesInfo')}>
                <InfoCircleOutlined className="icon__blue ml-1" />
              </AntTooltip>
            </span>
          </div>
          {submitCount > 0 && errors.options && (
            <div className="has-error">
              <span className="ant-form-explain ">
                {Array.isArray(errors.options)
                  ? t('settings:customFields.validation.fillInAllInputFields')
                  : errors.options}
              </span>
            </div>
          )}
        </Form>
      )}
    </DrawerFormik>
  );
};

export default SettingsCustomFieldEditDrawer;
