import React from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { Alert, Radio, Spin } from 'antd';
import { t } from 'i18next';
import { ApplicationState } from '../../../reducers';
import { FileExportOption } from '../../../../types/import_export';
import { EBP_TYPE_ID } from '../../../constants/ImportExportConstants';

type ExportSelectFormatProps = {
  multipleBrands?: boolean;
  handleCurrentStep: (fileTypeId: number | null, fileType: string) => void;
};

const ExportSelectFormat: React.FC<ExportSelectFormatProps> = props => {
  const { setFieldValue, values } = useFormikContext<any>();
  const { exportFileTypes, fetchingApplicationResources } = useSelector(
    (state: ApplicationState) => {
      return {
        exportFileTypes: state.brand.brandExport.fileExportOptions as FileExportOption[],
        fetchingApplicationResources: state.resources.fetchingApplicationResources,
      };
    }
  );

  const handleChange = (id: number) => {
    const filteType = exportFileTypes.find(type => type.id === id);
    setFieldValue('fileTypeId', id);
    setFieldValue('fileTypeName', filteType?.name);
    props.handleCurrentStep(id, filteType?.name || '');
  };

  if (fetchingApplicationResources)
    return <Spin className="spinner-center" style={{ marginTop: '20px' }} />;

  return (
    <React.Fragment>
      <Radio.Group
        onChange={e => handleChange(e.target.value)}
        value={values.fileTypeId}
        className="export-select-format"
      >
        {exportFileTypes.map(type => (
          <Radio
            key={type.id}
            value={type.id}
            className="radio-style"
            data-testid={type.name}
            disabled={props.multipleBrands && type.id !== EBP_TYPE_ID}
          >
            {type.name}
          </Radio>
        ))}
      </Radio.Group>
      {props.multipleBrands && (
        <Alert message={t('exporter:selectFormatInfo')} type="info" showIcon />
      )}
    </React.Fragment>
  );
};

export default ExportSelectFormat;
