import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Alert, Modal, Switch, message } from 'antd';
import * as Yup from 'yup';
import { SelectValue } from 'antd/lib/select';
import DrawerFormik from '../../global/drawer/DrawerFormik';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import { ApplicationState } from '../../../reducers';
import FormSelect from '../../global/Forms/FormSelect';
import { AsyncDispatch } from '../../../../types/global';
import { createDescription } from '../../../actions/items/description/create';
import ContentEditor from './ContentEditor';
import { DescriptionType } from '../../../../types/description';
import saveTranslation from '../../../constants/SaveTranslation.json';
import { deleteDescription } from '../../../actions/items/description/delete';
import {
  updateDescription,
  updateDescriptionContent,
} from '../../../actions/items/description/update';
import {
  AUTO_GENERATED_DESCRIPTION_TYPE_IDS,
  LONG_DESCRIPTION_TYPE_ID,
} from '../../../constants/DescriptionConstants';
import { DefaultValue } from '../../../../types/brand_settings';

type DescriptionDrawerProps = {
  visible: boolean;
  onClose: () => void;
  title: string;
  description?: DescriptionType;
  showDelete?: boolean;
  suggestedRecordType?: number;
};

const DescriptionDrawer: React.FC<DescriptionDrawerProps> = props => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const { selectedItems, defaultValues, listDescriptionTypes, listLanguages } = useSelector(
    (state: ApplicationState) => {
      return {
        selectedItems: getSelectedItems(state),
        listDescriptionTypes: state.resources.data.description.types,
        listLanguages: state.resources.data.global.languages,
        defaultValues: state.settings.defaultValues,
      };
    }
  );

  const defaultLanguage = defaultValues.find(
    (defValue: DefaultValue) => defValue.resource_table === 'languages'
  );

  const getValidationSchema = () => {
    return Yup.object().shape({
      contents: Yup.array().when('autoGenerate', {
        is: (val: any) => !val,
        then: Yup.array()
          .min(1, t('validation:required'))
          .test('Only Empty?', t('validation:onlyEmptyCharacters'), contents => {
            const isValid = contents?.find(c => c.content?.trim().length > 0);
            return isValid;
          })
          .required(t('validation:required')),
      }),
      typeId: Yup.number().nullable().required(t('validation:required')),
    });
  };

  const handleDelete = () => {
    if (props.description) {
      Modal.confirm({
        title: t('description:deleteDescription'),
        okText: saveTranslation.yes,
        cancelText: saveTranslation.no,
        onOk() {
          return dispatch(deleteDescription(props.description!.id)).then(() => {
            props.onClose();
          });
        },
      });
    }
  };

  return (
    <DrawerFormik
      title={props.title}
      visible={props.visible}
      onClose={() => {
        props.onClose();
      }}
      onDelete={props.showDelete ? () => handleDelete() : undefined}
      initialValues={{
        selectedItemId: selectedItems[0].id,
        typeId: props.description?.type_id || props.suggestedRecordType || null,
        languageId:
          props.description?.language_id ||
          (!props.description && defaultLanguage && Number(defaultLanguage.value)) ||
          null,
        autoGenerate: props.description?.auto_generate || 0,
        contents: props.description?.contents || [],
        autoDescription: props.description?.generated_content || '',
      }}
      validationSchema={getValidationSchema()}
      width="60%"
      onSubmit={values => {
        const filteredContents = values.contents.filter((c: any) => c.content?.trim().length > 0);

        if (!props.description) {
          return dispatch(
            createDescription({
              selectedItemId: values.selectedItemId,
              typeId: values.typeId,
              languageId: values.languageId,
              autoGenerate: values.autoGenerate,
              contents: filteredContents,
            })
          )
            .then(() => props.onClose())
            .catch(error => {
              if (error.response.status === 422)
                message.error(t('description:descriptionAlreadyExistsError'));
            });
        }
        return Promise.all([
          dispatch(
            updateDescription(
              props.description.id,
              values.typeId,
              values.autoGenerate,
              values.languageId
            )
          ),
          dispatch(updateDescriptionContent(filteredContents, props.description.id)),
        ]).finally(() => {
          props.onClose();
        });
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form layout="vertical" className="description-drawer__edit-form">
          <Row gutter={16} className="pb-2">
            <Col span={12}>
              <FormSelect
                showSearch
                label={t('common:type')}
                placeholder={t('description:typePlaceholder')}
                name="typeId"
                values={listDescriptionTypes}
                onChange={(type: SelectValue) => {
                  setFieldValue('typeId', type);
                  if (
                    AUTO_GENERATED_DESCRIPTION_TYPE_IDS.includes(Number(type)) &&
                    values.autoGenerate === 1
                  ) {
                    Number(type) === LONG_DESCRIPTION_TYPE_ID
                      ? setFieldValue('autoDescription', selectedItems[0].short_name)
                      : setFieldValue('autoDescription', selectedItems[0].application_summary);
                  } else {
                    setFieldValue('autoGenerate', 0);
                  }
                }}
                allowClear
                required
                testId="type"
              />
            </Col>
            <Col span={12}>
              <FormSelect
                showSearch
                label={t('description:language')}
                placeholder={t('description:language')}
                name="languageId"
                values={listLanguages}
                allowClear
              />
            </Col>
          </Row>
          <Form.Item
            label={
              <div
                className="inline-flex w-full"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="description-drawer__label-title">
                  {t('glossary:segments.description')}
                </div>
                {AUTO_GENERATED_DESCRIPTION_TYPE_IDS.includes(values.typeId) && (
                  <div className="flex-1 flex justify-end">
                    <div className="pr-1">{t('description:manual')}</div>
                    <Switch
                      size="small"
                      defaultChecked={values.autoGenerate !== 0}
                      onChange={() => {
                        const autoGenerate = !values.autoGenerate ? 1 : 0;
                        setFieldValue('autoGenerate', autoGenerate);
                        if (autoGenerate === 1) {
                          values.typeId === LONG_DESCRIPTION_TYPE_ID
                            ? setFieldValue('autoDescription', selectedItems[0].short_name)
                            : setFieldValue(
                                'autoDescription',
                                selectedItems[0].application_summary
                              );
                        }
                      }}
                    />
                    <div className="pl-1">{t('description:auto')}</div>
                  </div>
                )}
              </div>
            }
            validateStatus={errors.contents && touched.contents ? 'error' : undefined}
            help={(touched.contents && errors.contents) || undefined}
          >
            {values.autoGenerate === 0 ? (
              <ContentEditor
                contents={values.contents}
                typeId={values.typeId}
                onChange={(contents: any) => setFieldValue('contents', contents)}
                listDescriptionTypes={listDescriptionTypes}
              />
            ) : (
              <div className="description__auto-description border border-solid border-gray-500 rounded bg-gray-300">
                <div className="pl-6 pt-2 pb-2 pr-2">
                  {values.autoDescription || t('description:descriptionText')}
                </div>
              </div>
            )}
          </Form.Item>
          {values.autoGenerate === 0 && (
            <div className="description__analyses">
              <Alert
                className="m-4"
                message={t('description:lineBreakNote')}
                type="info"
                showIcon
              />
            </div>
          )}
        </Form>
      )}
    </DrawerFormik>
  );
};

export default DescriptionDrawer;
