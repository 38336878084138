import React from 'react';
import { useTranslation } from 'react-i18next';
import { Steps, Button } from 'antd';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import ExportStarted from './ExportStarted';
import GapCoverageReportStep1 from './GapCoverageReportStep1';
import GapCoverageReportStep2 from './GapCoverageReportStep2';
import GapCoverageReportStep3 from './GapCoverageReportStep3';
import GapCoverageReportStep4 from './GapCoverageReportStep4';
import {
  fetchUsedPositions,
  fetchUsedSubConfigs,
  startGapCoverageExport,
} from '../../../actions/exporter';
import { ApplicationState } from '../../../reducers';
import { PartType } from '../../../../types/item';
import { AsyncDispatch } from '../../../../types/global';

const { Step } = Steps;

const GapCoverageReportSteps: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const [current, setCurrent] = React.useState(0);

  const { selectedBrandId, vehicleSubConfigs, positions } = useSelector(
    (state: ApplicationState) => ({
      selectedBrandId: state.parent.brands.selectedBrandId,
      vehicleSubConfigs: state.exporter.vehicleSubConfigs,
      positions: state.exporter.positions,
    })
  );

  const getDefaultSelectedYears = (startYear: number) => {
    const currentYear = new Date().getFullYear();
    const years: number[] = [];
    while (startYear <= currentYear) {
      years.push(startYear++);
    }
    return years;
  };

  const goToNextStep = () => {
    setCurrent(current + 1);
  };

  return (
    <Formik
      initialValues={{
        filterId: null,
        partSelection: '',
        selectedPartTypes: [],
        exportApplication: {
          makes: vehicleSubConfigs.makes || [],
          models: vehicleSubConfigs.models || [],
          years: getDefaultSelectedYears(new Date().getFullYear() - 24),
          vehicle_types: vehicleSubConfigs.vehicle_types
            ? vehicleSubConfigs.vehicle_types
            : [5, 6, 7],
          vehicle_type_groups: vehicleSubConfigs.vehicle_groups
            ? vehicleSubConfigs.vehicle_groups
            : [2],
        },
        positionIds: positions.map(p => p.id) || [],
        subConfigTypeIds: [],
        regions: [1, 2, 3],
      }}
      onSubmit={(values, formikActions) => {
        const { setSubmitting } = formikActions;
        setSubmitting(true);
        dispatch(
          // @ts-ignore
          startGapCoverageExport({
            brandId: selectedBrandId,
            filterId: values.filterId,
            selectedPartTypes: values.selectedPartTypes.map((partType: PartType) => partType.id),
            makes: values.exportApplication.makes,
            models: values.exportApplication.models,
            years: values.exportApplication.years,
            vehicleTypes: values.exportApplication.vehicle_types,
            vehicleTypeGroups: values.exportApplication.vehicle_type_groups,
            positionIds: values.positionIds,
            subConfigTypeIds: values.subConfigTypeIds,
            regions: values.regions,
          })
        );
        goToNextStep();
        setSubmitting(false);
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => {
        const goToPrev = () => {
          const step = current - 1;
          setCurrent(step);
        };

        const steps = [
          {
            title: t('gapCoverageReport:selectParts'),
            content: <GapCoverageReportStep1 handleCurrentStep={() => setCurrent(current + 1)} />,
          },
          {
            title: t('gapCoverageReport:selectPartTypes'),
            content: <GapCoverageReportStep2 />,
          },
          {
            title: t('gapCoverageReport:selectApplications'),
            content: <GapCoverageReportStep3 />,
          },
          {
            title: t('gapCoverageReport:selectConfiguration'),
            content: <GapCoverageReportStep4 />,
          },
          {
            title: t('common:export'),
            content: <ExportStarted />,
          },
        ];

        const fetchSubConfigs = () =>
          dispatch(
            fetchUsedSubConfigs({
              categoryIds: values.selectedPartTypes.map((p: PartType) => p.id),
              makeIds: values.exportApplication.makes,
              modelIds: values.exportApplication.models,
              yearIds: values.exportApplication.years,
              vehicleTypeIds: values.exportApplication.vehicle_types,
              vehicleGroupIds: values.exportApplication.vehicle_type_groups,
            })
          ).then(res => {
            const usedSubConfigIds = res.value.data.sub_config_type_ids;
            setFieldValue(
              'subConfigTypeIds',
              usedSubConfigIds && usedSubConfigIds.length > 20
                ? usedSubConfigIds.slice(0, 20)
                : usedSubConfigIds || []
            );
          });

        const fetchPositions = () =>
          dispatch(
            fetchUsedPositions({
              categoryIds: values.selectedPartTypes.map((p: PartType) => p.id),
              makeIds: values.exportApplication.makes,
              modelIds: values.exportApplication.models,
              yearIds: values.exportApplication.years,
              vehicleTypeIds: values.exportApplication.vehicle_types,
              vehicleGroupIds: values.exportApplication.vehicle_type_groups,
            })
          ).then(res => {
            const positions = res.value.data.positions;
            setFieldValue(
              'positionIds',
              positions.map((p: { id: number; value: string }) => p.id)
            );
          });

        const handleStepChange = (step: number) => {
          if (step < current) setCurrent(step);
          else if (step === 1 && values.partSelection) setCurrent(step);
          else if (step === 2 && values.selectedPartTypes.length > 0) setCurrent(step);
          else if (
            step === 3 &&
            values.exportApplication.models.length > 0 &&
            values.exportApplication.makes.length > 0 &&
            values.exportApplication.years.length > 0
          ) {
            fetchSubConfigs();
            fetchPositions();
            setCurrent(step);
          } else if (
            step === 4 &&
            values.partSelection &&
            values.selectedPartTypes.length > 0 &&
            values.exportApplication.models.length > 0 &&
            values.exportApplication.makes.length > 0 &&
            values.exportApplication.years.length > 0
          )
            handleSubmit();
        };

        const checkDisabledNext = () => {
          if (current === 0 && !values.partSelection && values.partSelection !== 'filter')
            return true;
          if (values.partSelection === 'filter' && !values.filterId) return true;
          if (current === 1 && values.selectedPartTypes.length === 0) return true;
          if (
            (current === 2 || current === 3) &&
            (values.exportApplication.models.length === 0 ||
              values.exportApplication.makes.length === 0 ||
              values.exportApplication.years.length === 0 ||
              values.selectedPartTypes.length === 0)
          )
            return true;
          return false;
        };

        return (
          <div className="export-steps__form">
            <Steps current={current} onChange={handleStepChange} className="export-steps">
              {steps.map(item => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>

            <div className="flex-1 flex flex-col overflow-hidden">
              <div className="steps-content flex-1 overflow-x-hidden overflow-y-auto">
                {steps[current].content}
              </div>

              <div className="flex mt-2">
                {current > 0 && (
                  <Button style={{ margin: '0 8px' }} onClick={() => goToPrev()}>
                    {t('exporter:back')}
                  </Button>
                )}
                {current < steps.length - 2 && (
                  <Button
                    type="primary"
                    onClick={() => {
                      if (current === 2) {
                        fetchSubConfigs();
                        fetchPositions();
                      }
                      setCurrent(current + 1);
                    }}
                    disabled={checkDisabledNext()}
                    data-testid="next"
                  >
                    {t('exporter:next')}
                  </Button>
                )}
                {current === steps.length - 2 && (
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    disabled={checkDisabledNext()}
                    data-testid="export"
                  >
                    {t('common:export')}
                  </Button>
                )}
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default GapCoverageReportSteps;
