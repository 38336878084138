import axios from 'axios';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { OrderCondition, OrderOption } from '../../../../types/catalogue';
import { Item } from '../../../../types/item';

export function checkItem(itemId: number) {
  return {
    type: 'CHECK_ITEM',
    payload: { itemId },
  };
}

export function multiCheckItems(itemIds: number[], add: boolean) {
  return {
    type: 'MULTI_CHECK_ITEMS',
    payload: { itemIds, add },
  };
}

export function selectAllItems() {
  return {
    type: 'SELECT_ALL_ITEMS',
  };
}

export function addToSelectedItems(itemId: number) {
  return {
    type: 'ADD_TO_SELECTED_ITEMS',
    payload: { itemId },
  };
}

export function selectItem(itemId: number) {
  return {
    type: 'SELECT_ITEM',
    payload: { itemId },
  };
}

export function multiSelectItems(itemIds: number[], selectedItemId: number, add?: boolean) {
  return {
    type: 'MULTI_SELECT_ITEMS',
    payload: { itemIds, selectedItemId },
    meta: { add },
  };
}

export function updateItem(itemId: number, item: Item) {
  const params = utils.createURL([{ name: 'item_id', values: itemId }]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user_id, changed_by_user_id, ...restItem } = item;

  return {
    type: 'UPDATE_ITEM',
    payload: axios.patch(`${getApiRoot()}/items/v2${params}`, {
      item: restItem,
    }),
  };
}

export function updateClientFilterBrandIds(brandIds: number[]) {
  return {
    type: 'UPDATE_CATALOGUE_QUERY_PARAMS_BRAND_IDS',
    payload: brandIds,
  };
}

export function updateClientFilterChannelsReceivers({
  selectedChannelId,
  selectedReceiverId,
}: {
  selectedChannelId?: number | null;
  selectedReceiverId?: number | null;
}) {
  return {
    type: 'UPDATE_CATALOGUE_QUERY_PARAMS_CHANNEL_RECEIVER',
    payload: {
      selectedChannelId,
      selectedReceiverId,
    },
  };
}

export function setOrder({
  condition,
  option,
}: {
  condition: OrderCondition;
  option: OrderOption;
}) {
  return {
    type: 'UPDATE_CATALOGUE_QUERY_PARAMS_ORDER',
    payload: { condition, option },
  };
}

export function resetOrder() {
  return {
    type: 'RESET_CATALOGUE_QUERY_PARAMS_ORDER',
  };
}

export const updatePageFormDirtyState = (dirty: boolean) => {
  return {
    type: 'UPDATE_PAGE_FORMIK_DIRTY_STATE',
    payload: { dirty },
  };
};

export const setItemFetchRequired = (required: boolean) => {
  return {
    type: 'SET_ITEM_FETCH_REQUIRED',
    payload: { required },
  };
};
