import { connect } from 'react-redux';
import React from 'react';
import { Input } from 'antd';
import queryString from 'query-string';
import { withTranslation, WithTranslation } from 'react-i18next';
import FileSaver from 'file-saver';
import { ApplicationState } from '../../reducers';
import { AsyncDispatch } from '../../../types/global';
import { withContainerWrapper } from '../ContainerWrapper';
import {
  fetchDeliveryLogs,
  fetchDeliveryErrorLogs,
  fetchDeliveryFileUrl,
  fetchDeliveryErrorLogsExcel,
} from '../../actions/distribution_logs';
import { UserType } from '../../../types/user';
import { getPageLimit, typingDone } from '../../utils/Utils';

import { DeliveryLog, DeliveryLogError } from '../../../types/delivery_logs';
import DeliveryLogTable from '../../components/body/delivery_logs/DeliveryLogTable';
import { ChannelResources, Global } from '../../../types/resources';
import { isReceiver } from '../../utils/UserUtils';
import { hasPermission } from '../../utils/Permissions';
import { intercomEvent } from '../../utils/IntercomUtils';
import { withRouter, WithRouterProps } from '../withRouter';

type DeliveryLogsProps = {
  dispatch: AsyncDispatch;
  user: UserType;
  channelResources: ChannelResources;
  globalResources: Global;
  fetchingLogs: boolean;
  fetchingLogErrors: boolean;
  deliveryLogs: DeliveryLog[];
  deliveryLogErrors: DeliveryLogError[];
} & WithRouterProps &
  WithTranslation;

type DeliveryLogsState = {
  filterKeywords: string;
  pageSize: number;
};

export class DeliveryLogsContainer extends React.Component<DeliveryLogsProps, DeliveryLogsState> {
  constructor(props: DeliveryLogsProps) {
    super(props);
    const locationState: { searchKeyword: string } = props.location.state as any;

    this.state = {
      filterKeywords: props.location.state ? locationState.searchKeyword : '',
      pageSize: getPageLimit(),
    };
  }

  componentDidMount() {
    const urlSearch = queryString.parse(location.search);

    const keywordArray = Object.keys(urlSearch).map(key => {
      return `${key}:"${urlSearch[key]}"`;
    });
    const keywords = keywordArray.join(' ');
    if (keywords) this.handleKeywordChange(keywords);
    else this.handleKeywordChange(this.state.filterKeywords);

    intercomEvent('viewed-history');
  }

  fetchErrorLogs = (id: number) => {
    this.props.dispatch(fetchDeliveryErrorLogs(id));
  };

  fetchFileUrl = (logId: number) => {
    this.props.dispatch(fetchDeliveryFileUrl(logId));
  };

  fetchDeliveryLogs = (page = 1) => {
    const { user } = this.props;
    const { pageSize, filterKeywords } = this.state;
    return this.props.dispatch(
      fetchDeliveryLogs({
        parentId: user.parent_id,
        limit: pageSize,
        keywords: filterKeywords,
        receiverId: user.receiver_id,
        page,
      })
    );
  };

  handleKeywordChange = async (keywords: string) => {
    await this.setState({ filterKeywords: keywords });
    typingDone(() => this.fetchDeliveryLogs());
  };

  handleTableScroll = ({ startIndex, stopIndex }: { startIndex: number; stopIndex: number }) => {
    const { pageSize } = this.state;

    const nextPage = Math.ceil(stopIndex / pageSize);
    const currentPage = Math.ceil(startIndex / pageSize);
    const lastPage = currentPage > startIndex / pageSize;

    if (!lastPage) return this.fetchDeliveryLogs(nextPage);
    return Promise.resolve();
  };

  saveImportLogsDetailsFile = ({ errorLogFile }: { errorLogFile: any }) => {
    const blob = new Blob([errorLogFile], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'delivery_export_errors.xlsx');
  };

  handleErrorsDownload = (logId: number) => {
    this.props
      .dispatch(fetchDeliveryErrorLogsExcel(logId))
      .then(response =>
        this.saveImportLogsDetailsFile({ errorLogFile: response.action.payload.data })
      );
  };

  render() {
    const { channelResources, user, t } = this.props;
    const { pageSize } = this.state;
    return (
      <div className="page-layout">
        <div className="page-layout__top-bar">
          <div className="page-layout__top-bar__container">
            <Input.Search
              className="page-layout__top-bar__search"
              value={this.state.filterKeywords}
              onChange={e => this.handleKeywordChange(e.target.value)}
              placeholder={t('deliveryLog:search')}
              allowClear
            />
          </div>
        </div>
        <div className="page-layout__content flex">
          <DeliveryLogTable
            deliveryLogs={this.props.deliveryLogs}
            deliveryLogErrors={this.props.deliveryLogErrors}
            fetchingLogs={this.props.fetchingLogs}
            fetchingLogErrors={this.props.fetchingLogErrors}
            errorTypes={this.props.globalResources.log_error_types}
            fileTypes={channelResources.file_types}
            pageSize={pageSize}
            handleTableScroll={this.handleTableScroll}
            fetchErrorLogs={this.fetchErrorLogs}
            fetchFileUrl={this.fetchFileUrl}
            handleErrorsDownload={this.handleErrorsDownload}
            isReceiver={isReceiver(user)}
            canAccessLogDetails={hasPermission(user, 'can_access_log_details')}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  user: state.user.user,
  fetchingLogs: state.channel.logs.fetchingLogs,
  fetchingLogErrors: state.channel.logs.fetchingLogErrors,
  deliveryLogs: state.channel.logs.deliveryLogs,
  deliveryLogErrors: state.channel.logs.deliveryLogErrors,
  channelResources: state.resources.data.channel,
  globalResources: state.resources.data.global,
});

export default withRouter(
  connect(mapStateToProps)(withContainerWrapper(withTranslation()(DeliveryLogsContainer)))
);
