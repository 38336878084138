import React, { useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { Button, Input } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { ApplicationState } from '../../../reducers';
import PageFormik, { CustomFormikActions } from '../../global/page/PageFormik';
import { AsyncDispatch } from '../../../../types/global';
import { fetchAttributes, fetchAttributesCount } from '../../../actions/items/attributes/fetch';
import AttributesTable from './AttributesTable';
import { Attribute, AttributeRankedValue } from '../../../../types/attributes';
import { generateUUID, getPageLimit, typingDone } from '../../../utils/Utils';
import { intercomEvent } from '../../../utils/IntercomUtils';
import {
  attributesMappedWithUniqueId,
  getAttributesMappedWithUniqueId,
} from '../../../selectors/attributes/attributeSelector';
import { updateAttributes } from '../../../actions/items/attributes/update';
import AttributeValuesDrawer from './AttributeValuesDrawer';
import AttributeNamePopover from './AttributeNamePopover';
import { getSelectedItems } from '../../../selectors/catalogue/catalogueSelector';
import IntercomFormEventHandler from '../../global/page/IntercomFormEventHandler';
import { hasPermission } from '../../../utils/Permissions';

const { Search } = Input;
const AttributesPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch: AsyncDispatch = useDispatch();

  const {
    selectedItems,
    selectedBrandItemIds,
    mappedAttributes,
    brandId,
    attributeValues,
    filterId,
    canMaintainProduct,
  } = useSelector((state: ApplicationState) => {
    return {
      selectedItems: getSelectedItems(state),
      selectedBrandItemIds: state.catalogue.catalogue.allSelectedItemIds,
      mappedAttributes: attributesMappedWithUniqueId(state),
      brandId: state.parent.brands.selectedBrandId,
      attributeValues: state.items.attributes.rankedValues,
      filterId: state.catalogue.filter.filterGo!.id,
      canMaintainProduct: hasPermission(state.user.user, 'can_maintain_products'),
    };
  });

  const [searchKeywords, setSearchKeywords] = React.useState<string>('');
  const [selectedItemCount, setSelectedItemCount] = React.useState<number>(0);
  const [selectedRows, setSelectedRows] = React.useState<(number | string)[]>([]);
  const [selectedCells, setSelectedCells] = React.useState<
    { rowIndex: number; columnIndex: number }[]
  >([]);
  const [selectedAttributeId, setSelectedAttributeId] = React.useState<number | string | undefined>(
    undefined
  );
  const [openDrawer, setOpenDrawer] = React.useState<boolean>(true);

  const latestValuesRef = useRef(attributeValues);

  React.useEffect(() => {
    latestValuesRef.current = attributeValues;
  }, [attributeValues]);

  const sendIntercomSaveEvents = (renamed: boolean, newAttribute: boolean, newValue: boolean) => {
    const part_numbers = selectedItems.map(i => i.part_number).toString();
    const brand_code = selectedItems[0]?.brand_code;
    const action = 'item-saved';
    const event = 'viewed-all-product';
    if (renamed)
      intercomEvent(event, { action, location: 'attribute_name_edit', part_numbers, brand_code });
    if (newAttribute)
      intercomEvent(event, { action, location: 'attribute_new', part_numbers, brand_code });
    if (newValue)
      intercomEvent(event, { action, location: 'attribute_value_new', part_numbers, brand_code });
  };

  const handleSelect = (
    selectedItemsCount: number,
    selectedRows: (number | string)[],
    focusedCells: { rowIndex: number; columnIndex: number }[],
    attributes: Attribute[]
  ) => {
    if (selectedRows.length === 1) {
      setSelectedAttributeId(selectedRows[0]);
    } else if (focusedCells.length) {
      const selectedRowIndices = [...new Set(focusedCells.map(c => c.rowIndex))];
      if (selectedRowIndices.length === 1) {
        const attribute = attributes[selectedRowIndices[0]];
        setSelectedAttributeId(attribute.id || attribute.uniqueId);
      } else {
        setSelectedAttributeId(undefined);
      }
    } else {
      setSelectedAttributeId(undefined);
    }

    setSelectedItemCount(selectedItemsCount);
    setSelectedRows(selectedRows);
    setSelectedCells(focusedCells);
  };

  const handleSubmit = (values: FormikValues, formikActions: CustomFormikActions) => {
    const { setSubmitPending, setSubmitSuccess, setSubmitError } = formikActions;
    setSubmitPending();
    const editedValues: (Attribute & { renamedAttributeId?: number })[] = values.attributes.filter(
      (v: any) => v.temp
    );
    const attributesUpdate: any[] = [];
    selectedBrandItemIds.forEach((id: number) => {
      const updatedValues: any[] = [];
      editedValues.forEach(attribute => {
        const values: any[] = [];
        attribute.ranked_values.forEach((rankValue: AttributeRankedValue) => {
          let uomId: number | null = null;
          rankValue.part_attribute_meta_uoms.find(uom => {
            if (uom.item_ids.includes(id)) {
              if (uom.id) uomId = uom.id;
            }
          });
          if (rankValue.item_ids.includes(id)) {
            if (rankValue.id) values.push({ id: rankValue.id, part_attribute_meta_uom_id: uomId });
            else values.push({ name: rankValue.name, part_attribute_meta_uom_id: uomId });
          }
        });
        if (attribute.renamedAttributeId)
          updatedValues.push({ id: attribute.renamedAttributeId, values: [] });
        if (attribute.id) updatedValues.push({ id: attribute.id, values });
        else updatedValues.push({ name: attribute.name, values });
      });
      attributesUpdate.push({ item_id: id, attributes: updatedValues });
    });

    const renamedAttribute = editedValues.find(a => !!a.renamedAttributeId);
    const newAttribute = editedValues.find(a => !a.id && !a.renamedAttributeId);
    const newValue = editedValues.find(a => a.ranked_values.find(v => !v.id));
    sendIntercomSaveEvents(!!renamedAttribute, !!newAttribute, !!newValue);

    dispatch(updateAttributes(attributesUpdate, values.attributes))
      .then(() => {
        dispatch(fetchAttributesCount(selectedBrandItemIds)).then(() => {
          setSubmitSuccess();
        });
      })
      .catch(() => setSubmitError());
  };

  const updateWithItemIds = (copiedItemId: number, updateItemIds: number[], itemIds: number[]) => {
    if (itemIds.includes(copiedItemId)) {
      return [...new Set([...itemIds, ...updateItemIds])];
    }
    return itemIds.filter(id => !updateItemIds.includes(id));
  };

  return (
    <PageFormik
      showNoError={!canMaintainProduct}
      enableReinitialize
      showAnalysis
      initialValues={{
        attributes: mappedAttributes,
      }}
      onSubmit={(values, actions) => handleSubmit(values, actions)}
      contentNoSpacing
      contentNoScroll
    >
      {({ values, setFieldValue, dirty, resetForm, setValues }) => {
        const addNewAttribute = (name: string) => {
          const uuid = generateUUID();
          const newAttribute: Attribute = {
            id: null,
            rank: null,
            pdm_recommendation: 0,
            receivers: [],
            used_by_brand: 0,
            name,
            description: null,
            item_ids: selectedBrandItemIds,
            record_number: null,
            ranked_values: [],
            uniqueId: uuid,
            temp: true,
          };
          setFieldValue('attributes', [newAttribute, ...values.attributes]);
        };

        const handleAddValue = ({
          attributeId,
          uomId,
          uomlabel,
          itemIds,
          valueId,
          valueName,
        }: {
          attributeId: number | string;
          uomId: number | null;
          uomlabel: string;
          itemIds: number[];
          valueId: number | null;
          valueName: string;
        }) => {
          // for new attributes always check with name and index should be as in formik initial values
          const uuid = generateUUID();

          const value = latestValuesRef.current.find(v => v.id === valueId);
          const uoms = [...(uomId ? [{ id: uomId, label: uomlabel, item_ids: itemIds }] : [])];

          const newValue: AttributeRankedValue = value
            ? {
                ...value,
                item_ids: itemIds,
                part_attribute_meta_uoms: uoms,
              }
            : {
                id: null,
                rank: 3,
                pdm_recommendation: 0,
                receiver_ids: [],
                used_by_brand: null,
                name: valueName,
                item_ids: itemIds,
                multi_value_record_number: null,
                part_attribute_meta_uoms: uoms,
                uniqueId: uuid,
              };

          const updatedAttributes = values.attributes.map((a: Attribute) => {
            if (a.id === attributeId || a.uniqueId === attributeId)
              return { ...a, ranked_values: [...a.ranked_values, newValue], temp: true };
            return a;
          });

          setFieldValue('attributes', updatedAttributes);
        };

        const defaultAttrFetchParams = {
          limit: getPageLimit(),
          page: 1,
          value_limit: 20,
          parent_owner_brand_id: brandId,
        };

        const handleKeywordChange = (keyword: string) => {
          setSearchKeywords(keyword);
          const parameters =
            selectedBrandItemIds.length < 201
              ? { item_ids: selectedBrandItemIds, filters: keyword, ...defaultAttrFetchParams }
              : { filter_id: filterId, filters: keyword, ...defaultAttrFetchParams };

          typingDone(() =>
            dispatch(fetchAttributes(parameters))
              .then(response => {
                if (dirty) {
                  const tempValues = values.attributes.filter((a: Attribute) => a.temp);
                  const tempIds = tempValues.map((t: Attribute) => t.uniqueId);
                  const filteredTempAttributes: Attribute[] = [];
                  response.action.payload.data.forEach((r: Attribute) => {
                    if (!tempIds.includes(r.id)) {
                      filteredTempAttributes.push({ ...r, uniqueId: r.id! });
                    }
                  });
                  resetForm();
                  setValues({ attributes: [...tempValues, ...filteredTempAttributes] });
                }
              })
              .catch(() => {
                if (dirty) {
                  const tempValues = values.attributes.filter((a: Attribute) => a.temp);
                  resetForm();
                  setValues({ attributes: [...tempValues] });
                }
              })
          );
        };

        const handleFetchNextAttributes = (attributesLength: number) => {
          const currentPage = Math.ceil(attributesLength / getPageLimit());
          const lastPage = currentPage > attributesLength / getPageLimit();

          if (!lastPage)
            return dispatch(
              fetchAttributes({
                item_ids: selectedBrandItemIds,
                parent_owner_brand_id: brandId,
                page: currentPage + 1,
                value_limit: 20,
                limit: getPageLimit(),
              })
            ).then(response => {
              if (dirty) {
                const fetchedAttributes = response.value.data;
                const newFetchedInitValues = getAttributesMappedWithUniqueId(fetchedAttributes);
                resetForm();
                setValues({ attributes: [...values.attributes, ...newFetchedInitValues] });
              }
            });
        };

        return (
          <React.Fragment>
            <div className="flex p-2">
              <Search
                value={searchKeywords}
                placeholder={t('common:search')}
                allowClear
                onChange={e => handleKeywordChange(e.target.value)}
                size="small"
                style={{ width: 350, marginRight: 8 }}
              />

              <AttributeNamePopover
                attributeName={searchKeywords}
                handleChange={(attributeName: string) => {
                  addNewAttribute(attributeName);
                }}
                isSearchKeyword
              >
                <Button type="primary" ghost size="small" data-testid="add-attribute">
                  {t('attributes:addAttribute')}
                </Button>
              </AttributeNamePopover>

              {selectedBrandItemIds.length > 1 && (
                <div className="pl-2 text-gray-600">
                  {` (${selectedItemCount} ${t('attributes:of')} ${selectedBrandItemIds.length} 
                       ${t('attributes:productsSelected')}) `}
                </div>
              )}
            </div>
            <div className="flex h-full">
              <AttributesTable
                attributes={values.attributes}
                selectedCells={selectedCells}
                selectedRows={selectedRows}
                openDrawer={openDrawer}
                handleAddValue={data => handleAddValue(data)}
                handleCopiedCellData={({
                  updateItemIds,
                  copiedItemId,
                  copiedCells,
                }: {
                  updateItemIds: number[];
                  copiedItemId: number;
                  copiedCells: { rowIndex: number; columnIndex: number }[];
                }) => {
                  const copiedAttributes: Attribute[] = copiedCells.map(
                    c => values.attributes[c.rowIndex]
                  );
                  const updatedAttributes = copiedAttributes.map(ca => ({
                    ...ca,
                    temp: true,
                    item_ids: updateWithItemIds(copiedItemId, updateItemIds, ca.item_ids),
                    ranked_values: ca.ranked_values.map(rv => ({
                      ...rv,
                      item_ids: updateWithItemIds(copiedItemId, updateItemIds, rv.item_ids),
                      part_attribute_meta_uoms: rv.part_attribute_meta_uoms.map(uom => ({
                        ...uom,
                        item_ids: updateWithItemIds(copiedItemId, updateItemIds, uom.item_ids),
                      })),
                    })),
                  }));
                  const updatedAtrributes = values.attributes.map(
                    (a: Attribute) => updatedAttributes.find(ua => ua.id === a.id) || a
                  );
                  setFieldValue('attributes', updatedAtrributes);
                }}
                handleSelect={(
                  selectedItemsCount: number,
                  selectedRows: (number | string)[],
                  focusedCells: { rowIndex: number; columnIndex: number }[]
                ) =>
                  handleSelect(selectedItemsCount, selectedRows, focusedCells, values.attributes)
                }
                handleFetchNextAttributes={() =>
                  handleFetchNextAttributes(values.attributes.length)
                }
              />
              {selectedBrandItemIds.length > 1 && (
                <div
                  style={{ width: `${openDrawer ? 350 : 50}px` }}
                  className="attribute__values-drawer z-0"
                >
                  <div className="flex items-center">
                    <Button type="text" onClick={() => setOpenDrawer(!openDrawer)}>
                      {openDrawer ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                    {openDrawer && (
                      <div className="ml-2 text-gray-800">{t('attributes:addValues')}</div>
                    )}
                  </div>
                  <div className="h-full w-full">
                    {openDrawer && (
                      <AttributeValuesDrawer
                        selectedAttributeId={selectedAttributeId}
                        attributes={values.attributes}
                        selectedRows={selectedRows}
                        selectedCells={selectedCells}
                        selectedItemCount={selectedItemCount}
                        handleAddValue={handleAddValue}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>

            <IntercomFormEventHandler
              segment="attribute"
              partNr={selectedItems.map(i => i.part_number).toString()}
              brandCode={selectedItems[0]?.brand_code}
            />
          </React.Fragment>
        );
      }}
    </PageFormik>
  );
};

export default AttributesPage;
