import React, { useMemo, useCallback } from 'react';
import { FormikValues } from 'formik';
import { Switch, Modal, Form, Radio, message } from 'antd';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import DrawerFormik from '../../global/drawer/DrawerFormik';
import FormInput from '../../global/Forms/FormInput';
import HelpCenter from '../../../containers/HelpCenter';
import FormSelect from '../../global/Forms/FormSelect';
import ColumnContentConfigurator from './ColumnContentConfigurator';
import {
  ExportBuilderAdvancedSegment,
  ExportBuilderAdvancedColumn,
  ExportBuilderAdvancedColumnUniqueIdentifier,
} from '../../../../types/export_builder_advanced';
import { updateExportBuilderAdvancedColumn } from '../../../actions/brand/export_builder_advanced/update';
import { AsyncDispatch } from '../../../../types/global';
import ExportBuilderPlusFilterFields from './ExportBuilderPlusFilterFields';
import SaveContinueModal from '../../global/drawer/SaveContinueModal';
import { ChannelColumn } from '../../../../types/channel';
import { EBP_SEGMENTS } from '../../../constants/ImportExportConstants';

const { confirm } = Modal;

type ExportBuilderAdvancedDrawerProps = {
  fetchingSegment?: number[];
  selectedTemplateId: number;
  visible: boolean;
  onClose: () => void;
  selectNextColumn: () => void;
  selectPrevColumn: () => void;
  createNewColumn: () => void;
  segments: ExportBuilderAdvancedSegment[];
  templateColumns: any[];
  selectedColId: number;
  addRightToColId?: number | null;
  brandId?: number;
  lockedColumn?: boolean;
  segment?: string;
  updateColumnsOrder?: (cols: any[]) => void;
};

const ExportBuilderAdvancedDrawer: React.FC<ExportBuilderAdvancedDrawerProps> = ({
  fetchingSegment,
  selectedTemplateId,
  visible,
  templateColumns,
  selectedColId,
  onClose,
  selectNextColumn,
  selectPrevColumn,
  createNewColumn,
  segments,
  brandId,
  lockedColumn,
  segment,
  updateColumnsOrder,
  addRightToColId,
}) => {
  const dispatch: AsyncDispatch = useDispatch();
  const { t } = useTranslation();

  const [prevSelectedColId, setPrevSelectedColId] = React.useState<number | null>(
    addRightToColId || null
  );

  const selectedTemplateValues = templateColumns.find(
    (t: ExportBuilderAdvancedColumn | ChannelColumn) => t.id === selectedColId
  );

  const lockedHeader = selectedTemplateValues?.configuration.locked_header === 1;

  const [segmentId, setSegmentId] = React.useState<number | undefined>();
  const [fetchingCurrentSegment, setFetchingCurrentSegment] = React.useState(false);

  const [useAsEmptyColumn, setUseAsEmptyColumn] = React.useState(
    selectedTemplateValues ? selectedTemplateValues.configuration.segment_id === null : false
  );

  const [changeModalVisibleType, setChangeModalVisibleType] = React.useState('');

  const [goToOtherCol, setGoToOtherCol] = React.useState(false);

  React.useEffect(() => {
    setTimeout(() => {
      if (visible) {
        const input = document.getElementById('columnHeader');
        if (!selectedColId && input) input.focus();
      }
    }, 100);
  }, [visible]);

  React.useEffect(() => {
    if (
      fetchingSegment?.includes(segmentId || selectedTemplateValues?.configuration.segment_id || 1)
    ) {
      if (!fetchingCurrentSegment) setFetchingCurrentSegment(true);
    } else if (fetchingCurrentSegment) setFetchingCurrentSegment(false);
  }, [
    fetchingCurrentSegment,
    fetchingSegment,
    segmentId,
    selectedTemplateValues?.configuration.segment_id,
  ]);

  React.useEffect(() => {
    if (goToOtherCol) setGoToOtherCol(false);
  }, [goToOtherCol]);

  React.useEffect(() => {
    if (selectedTemplateValues)
      setUseAsEmptyColumn(selectedTemplateValues.configuration.segment_id === null);
    else setUseAsEmptyColumn(false);
  }, [selectedColId, selectedTemplateValues]);

  const createNew = () => {
    createNewColumn();
    setGoToOtherCol(true);
  };

  const selectNext = () => {
    setSegmentId(undefined);
    selectNextColumn();
    setGoToOtherCol(true);
  };

  const selectPrev = () => {
    setSegmentId(undefined);
    selectPrevColumn();
    setGoToOtherCol(true);
  };

  const getSegmentFields = (segmentId: number) => {
    const segment = segments.find(s => s.id === segmentId);
    return segment ? segment.fields : [];
  };

  const getFilterableFields = useCallback(
    (segmentId: number) => {
      const fields = segments.find(s => s.id === segmentId)?.fields || [];
      const selectedColumn = templateColumns.find(t => t.id === selectedColId);

      const filterableFields = fields
        ? // id 32 is the field "attribute" - visible for receivers to enter free text
          fields.filter(f => f.filterable === 1 || f.id === 32) || []
        : [];
      const mappedValuesOnFields = filterableFields.map(field => {
        if (!selectedColumn) return field;
        const selectedColumnUniqueIdentifiers = selectedColumn.unique_identifiers
          ? selectedColumn.unique_identifiers.find((i: any) => i.segment_field_id === field.id)
          : null;
        return {
          ...field,
          values: selectedColumnUniqueIdentifiers ? selectedColumnUniqueIdentifiers.values : [],
        };
      });
      return mappedValuesOnFields;
    },
    [segments, selectedColId, templateColumns]
  );

  const validationSchema = Yup.object().shape({
    segmentId: useAsEmptyColumn ? Yup.mixed() : Yup.number().required(t('validation:required')),
    columnHeader: Yup.string()
      .required(t('validation:required'))
      .test('check-unique-name', t('validation:nameInUse'), (val: any) =>
        templateColumns.length > 0 &&
        (selectedTemplateValues ? selectedTemplateValues.name !== val : true)
          ? templateColumns.every(({ name }) => name !== val)
          : true
      ),
    columnContent: Yup.string().when(['segmentId'], (segmentId: number, schema: any) =>
      segmentId ? schema.required(t('validation:required')) : schema
    ),
    groupSeperator: Yup.string().when(['groupActive'], (groupActive: number, schema: any) =>
      groupActive === 1 ? schema.required(t('validation:required')) : schema
    ),
    csvSeparator: Yup.string().when(['fileTypeId'], (fileTypeId: number, schema: any) =>
      fileTypeId === 1 ? schema.required(t('validation:required')) : schema
    ),
    groupActive: Yup.number(),
    uniqueIdentifiers: Yup.array().when(['groupActive'], (groupActive: number, schema: any) =>
      groupActive === 0
        ? Yup.array().of(
            Yup.object().shape({
              values: Yup.array().of(Yup.string()),
            })
          )
        : schema
    ),
  });

  const handleSubmit = (values: FormikValues, setSubmitting: any, resetForm: any) => {
    setSubmitting(true);
    const uniqueIdentifiers = values.uniqueIdentifiers
      .map(
        ({
          id,
          values,
          parent_owner_brand_id,
        }: {
          id: number;
          values: string[];
          parent_owner_brand_id: number;
        }) => ({
          parent_owner_brand_id: parent_owner_brand_id || brandId,
          segment_field_id: id,
          values,
        })
      )
      .filter(
        (col: ExportBuilderAdvancedColumnUniqueIdentifier) => col.values && col.values.length > 0
      );

    const updateObj = {
      ...(selectedTemplateValues && { id: selectedTemplateValues.id }),
      template_id: selectedTemplateId,
      name: values.columnHeader,
      configuration: {
        parent_owner_brand_id: brandId,
        segment_id: values.segmentId,
        value: values.columnContent,
        group: values.groupActive,
        group_separator: values.groupSeperator,
      },
      ...(uniqueIdentifiers.length > 0 && {
        unique_identifiers: uniqueIdentifiers,
      }),
      code: selectedTemplateValues ? selectedTemplateValues.code : null,
      number: selectedTemplateValues
        ? selectedTemplateValues.number
        : Math.max(...[0, ...templateColumns.map(col => col.number)]) + 1,
    };

    dispatch(updateExportBuilderAdvancedColumn(updateObj))
      .then(response => {
        const newCol = response.value.data;

        if (prevSelectedColId) {
          const editedId = prevSelectedColId;
          const newIndex = templateColumns.findIndex((c: any) => c.id === editedId) + 1;
          const mergedColumns = [
            ...templateColumns.slice(0, newIndex),
            newCol,
            ...templateColumns.slice(newIndex),
          ];
          if (updateColumnsOrder) updateColumnsOrder(mergedColumns);
        }

        setSubmitting(false);

        if (values.changeModalVisibleType || changeModalVisibleType) {
          setChangeModalVisibleType('');

          if (values.changeModalVisibleType === 'create') {
            createNewColumn();
            setPrevSelectedColId(newCol.id);
            setUseAsEmptyColumn(false);
            resetForm({
              values: {
                columnContent: '',
                columnHeader: '',
                groupActive: 0,
                groupSeperator: '',
                segmentId: 1,
                uniqueIdentifiers: [],
                changeModalVisibleType: '',
              },
            });
          }
          if (values.changeModalVisibleType === 'prev') selectPrev();
          if (values.changeModalVisibleType === 'next') selectNext();
        } else {
          onClose();
        }
      })
      .catch(e => {
        setSubmitting(false);
        setChangeModalVisibleType('');
        message.error(e.message);
      });
  };

  const getInitialValues = useMemo(
    () => ({
      segmentId:
        selectedTemplateValues &&
        (!segmentId || segmentId === selectedTemplateValues.configuration.segment_id)
          ? selectedTemplateValues.configuration.segment_id
          : segmentId || 1,
      columnHeader:
        selectedTemplateValues &&
        (!segmentId || segmentId === selectedTemplateValues.configuration.segment_id)
          ? selectedTemplateValues.name
          : '',
      columnContent:
        selectedTemplateValues &&
        (!segmentId || segmentId === selectedTemplateValues.configuration.segment_id)
          ? selectedTemplateValues.configuration.value
          : '',
      uniqueIdentifiers:
        selectedTemplateValues &&
        (!segmentId || segmentId === selectedTemplateValues.configuration.segment_id)
          ? getFilterableFields(selectedTemplateValues.configuration.segment_id) || []
          : (segmentId && getFilterableFields(segmentId)) || [],
      groupActive:
        selectedTemplateValues &&
        (!segmentId || segmentId === selectedTemplateValues.configuration.segment_id)
          ? selectedTemplateValues.configuration.group
          : 0,
      groupSeperator:
        selectedTemplateValues &&
        (!segmentId || segmentId === selectedTemplateValues.configuration.segment_id)
          ? selectedTemplateValues.configuration.group_separator
          : '',
      changeModalVisibleType: '', // state variable is not accessable in handle submit
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFilterableFields, selectedTemplateValues]
  );

  return (
    <DrawerFormik
      visible={visible}
      title={
        selectedTemplateValues ? selectedTemplateValues.name : t('exportBuilderAdvanced:newColumn')
      }
      onClose={onClose}
      isLoading={segments.length === 0 || fetchingCurrentSegment}
      initialValues={getInitialValues}
      validationSchema={validationSchema}
      newButtonEnabled={!!selectedColId}
      onSubmit={(values: FormikValues, { setSubmitting, resetForm }) =>
        handleSubmit(values, setSubmitting, resetForm)
      }
      onNew={
        !segment
          ? dirty => {
              if (dirty) setChangeModalVisibleType('create');
              else {
                createNew();
                setPrevSelectedColId(selectedColId);
              }
            }
          : undefined
      }
      onNext={
        !lockedColumn
          ? dirty => {
              const index = templateColumns.findIndex(col => col.id === selectedColId);
              if (index === -1 || index + 1 === templateColumns.length) return;
              if (dirty) setChangeModalVisibleType('next');
              else selectNext();
            }
          : undefined
      }
      onPrev={
        !lockedColumn
          ? dirty => {
              const index = templateColumns.findIndex(col => col.id === selectedColId);
              if (index === 0) return;
              if (dirty) setChangeModalVisibleType('prev');
              else selectPrev();
            }
          : undefined
      }
      enableReinitialize={fetchingCurrentSegment || goToOtherCol}
      width="50%"
    >
      {({ setFieldValue, resetForm, handleSubmit, setValues, validateForm, values }) => {
        const initialFieldsAfterSegmentChange = ({
          value,
          updateValues,
        }: {
          value?: number;
          updateValues?: boolean;
        }) => {
          const filterableFields = value ? getFilterableFields(value) : [];
          const newValues = {
            ...values,
            segmentId: value || null,
            columnContent: '',
            groupActive: 1,
            groupSeperator: '<linebreak>',
            uniqueIdentifiers: filterableFields,
            changeModalVisibleType,
          };
          if (!updateValues) resetForm({ values: newValues });
          else setValues(newValues);
          setSegmentId(value);
        };

        const handleSegmentChange = (value: number) => {
          const valuesSelected = values.uniqueIdentifiers.filter(
            (ui: any) => ui.values && ui.values.length
          );
          if (valuesSelected.length === 0 && !values.columnContent) {
            initialFieldsAfterSegmentChange({ value });
          } else {
            confirm({
              title: t('exportBuilderAdvanced:chancingSegmentWarning'),
              onOk: () => {
                initialFieldsAfterSegmentChange({ value });
              },
            });
          }
        };

        return (
          <React.Fragment>
            <Form layout="vertical">
              <FormInput
                name="columnHeader"
                id="columnHeader"
                label={t('exportBuilderAdvanced:columnHeader')}
                required
                fastField
                disabled={lockedHeader}
                testId="column-header"
              />

              <div className="font-medium text-gray-900 text-base">
                {t('exportBuilderAdvanced:whatToExport')}
              </div>
              <div className="flex items-center">
                <FormSelect
                  testId="segment-select"
                  showSearch
                  disabled={useAsEmptyColumn}
                  name="segmentId"
                  onChange={(value: any) => handleSegmentChange(value)}
                  label={t('glossary:segment')}
                  values={EBP_SEGMENTS}
                  className="w-64"
                  required
                />
                <div className="flex items-center ml-8">
                  <div className="mr-2 text-gray-900">
                    {t('exportBuilderAdvanced:useStaticContent')}
                  </div>
                  <Switch
                    size="small"
                    checked={useAsEmptyColumn}
                    onChange={checked => {
                      setUseAsEmptyColumn(checked);
                      initialFieldsAfterSegmentChange({
                        value: checked ? undefined : segments[0].id,
                        updateValues: true,
                      });
                      validateForm();
                    }}
                  />
                </div>
              </div>

              <ColumnContentConfigurator fields={getSegmentFields(values.segmentId) || []} />

              {values.uniqueIdentifiers.length > 0 && (
                <div className="mt-6">
                  <div className="pb-3 text-gray-900">
                    {t('exportBuilderAdvanced:singleOrMultiRow')}
                    <HelpCenter
                      id="advanced_export_builder"
                      popoverWidth={300}
                      content={<div>{t('exportBuilderAdvanced:multiRowInfoText')}</div>}
                    />
                  </div>

                  <div className="flex mb-3">
                    <div className="mr-4">
                      <Radio.Group
                        onChange={e => setFieldValue('groupActive', e.target.value)}
                        value={values.groupActive}
                      >
                        <Radio style={{ display: 'block', paddingBottom: '5px' }} value={1}>
                          {t('exportBuilderAdvanced:multiRecordRow')}
                        </Radio>
                        <Radio style={{ display: 'block' }} value={0}>
                          {t('exportBuilderAdvanced:singleRecordRow')}
                        </Radio>
                      </Radio.Group>
                    </div>

                    {values.groupActive === 1 && (
                      <React.Fragment>
                        <div className="mr-4">
                          <Radio.Group
                            size="small"
                            onChange={e => {
                              const { value } = e.target;
                              if (value === '<linebreak>') {
                                setFieldValue('groupSeperator', '<linebreak>');
                              } else {
                                setFieldValue('groupSeperator', '');
                              }
                            }}
                            value={
                              values.groupSeperator === '<linebreak>'
                                ? '<linebreak>'
                                : '<seperator>'
                            }
                          >
                            <Radio.Button value="<linebreak>">
                              {t('exportBuilderAdvanced:linebreak')}
                            </Radio.Button>
                            <Radio.Button value="<seperator>">
                              {t('exportBuilderAdvanced:delimeter')}
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                        {values.groupSeperator !== '<linebreak>' && (
                          <div className="w-64 mr-2 eba__group-seperator">
                            <FormInput
                              name="groupSeperator"
                              size="small"
                              placeholder={t('exportBuilderAdvanced:groupDelimeter')}
                              fastField
                            />
                          </div>
                        )}
                      </React.Fragment>
                    )}
                  </div>

                  <div className="pb-3 mt-5 text-gray-900">
                    {t('exportBuilderAdvanced:valueIdentifier')}
                  </div>

                  <div
                    className="flex border rounded border-solid border-gray-500"
                    style={{ maxHeight: 320 }}
                  >
                    <div className="p-4 pr-0 flex-1 flex flex-wrap overflow-auto">
                      {values.uniqueIdentifiers.length > 0 && <ExportBuilderPlusFilterFields />}
                    </div>
                  </div>
                </div>
              )}
            </Form>

            <SaveContinueModal
              showModal={!!changeModalVisibleType}
              onCancel={() => setChangeModalVisibleType('')}
              selectNext={() => {
                if (changeModalVisibleType === 'next') selectNext();
                else if (changeModalVisibleType === 'prev') selectPrev();
                else initialFieldsAfterSegmentChange({ value: segments[0].id });
                setChangeModalVisibleType('');
              }}
              saveAndSelectNext={async () => {
                try {
                  setFieldValue('changeModalVisibleType', changeModalVisibleType);
                  handleSubmit();
                  setChangeModalVisibleType('');
                } catch (error) {
                  setChangeModalVisibleType('');
                }
              }}
            />
          </React.Fragment>
        );
      }}
    </DrawerFormik>
  );
};

export default ExportBuilderAdvancedDrawer;
