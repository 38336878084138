import React, { useState } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';
import { Button, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import Page from '../../global/page/Page';
import { intercomEvent } from '../../../utils/IntercomUtils';

type InsightsPageProps = {
  embedToken?: string;
  embedUrl?: string;
  sku?: string;
  currentReportId: string;
  setReportId: (reportId: string) => void;
};

const InsightsPage: React.FC<InsightsPageProps> = props => {
  const { t } = useTranslation();

  React.useEffect(() => {
    intercomEvent('viewed-insights-item');
  }, []);

  const [report, setReport] = useState<Report>();
  const [reportIdInput, setReportIdInput] = React.useState(props.currentReportId);

  // const getReportFilters = async () => {
  //   report?.getFilters().then(result => {
  //     console.log(result);
  //   });

  //   // Retrieve the page collection and get the filters for the active page.
  //   const pages = await report?.getPages();
  //   // Retrieve the page that contain the filters. For the sample report it will be the active page
  //   const page = pages?.filter(page => {
  //     return page.isActive;
  //   })[0];

  //   const filters = await page?.getFilters();
  //   console.log(filters);
  // };

  // if (report) getReportFilters();

  const getFilters = () => {
    if (props.sku)
      return [
        {
          $schema: 'http://powerbi.com/product/schema#basic',
          filterType: 1,
          operator: 'In',
          requireSingleSelection: false,
          target: {
            column: 'Sku',
            table: 'statistic pdm_listing_offers',
          },
          values: [props.sku],
        },
      ];
  };

  return (
    <Page
      renderHeader={
        <div className="pl-5 flex">
          <span className="min-w-max mr-2"> {`${t('insights:reportId')}:`}</span>
          <Input
            className="insights__report-input"
            value={reportIdInput}
            onChange={e => setReportIdInput(e.target.value)}
            placeholder={t('insights:reportId')}
            size="small"
          />
          <Button
            type="primary"
            ghost
            onClick={() => props.setReportId(reportIdInput)}
            size="small"
          >
            {t('insights:changeReport')}
          </Button>
        </div>
      }
      showExpandButton
    >
      <div className="insights">
        {props.embedUrl && props.embedToken && (
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna
              id: props.currentReportId,
              embedUrl: props.embedUrl,
              accessToken: props.embedToken,
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: true,
                    visible: true,
                  },
                },
                background: models.BackgroundType.Transparent,
              },
              filters: getFilters(),
            }}
            cssClassName="insights__report"
            getEmbeddedComponent={embeddedReport => {
              setReport(embeddedReport as Report);
            }}
          />
        )}
      </div>
    </Page>
  );
};

export default InsightsPage;
