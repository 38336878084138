import { Form, Button, Col, Row, Card, message, Alert, Spin } from 'antd';
import { Formik, FormikHelpers, FormikValues } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { withTranslation, WithTranslation } from 'react-i18next';

import { ApplicationState } from '../../../reducers';
import { withContainerWrapper } from '../../../containers/ContainerWrapper';
import {
  deliveryValidationSchema,
  scheduleValidationSchema,
} from '../../../utils/ChannelValidationSchemas';
import { AsyncDispatch } from '../../../../types/global';
import {
  ExtendedResources,
  StandardResourceCodeDescription,
  StandardResourceConfig,
} from '../../../../types/resources';
import {
  Channel,
  FTP,
  DefaultSchedule,
  Plan,
  FileNameTemplate,
  ChannelFileExportOption,
  ChannelExportOptions,
} from '../../../../types/channel';
import { Brand } from '../../../../types/brand';
import { ExportBuilderAdvancedTemplate } from '../../../../types/export_builder_advanced';
import { ReceiverCatalogueBrandType } from '../../../../types/receiver_data_stream';
import FormInput from '../../global/Forms/FormInput';
import FormErrorFocus from '../../global/Forms/FormErrorFocus';
import ChannelFormDirtyHandler from './ChannelFormDirtyHandler';
import ChannelDefaultSettings from './ChannelDefaultSettings';
import validationMessages from '../../../constants/ValidationMessages.json';
import ChannelPlanList from './ChannelPlanList';
import NamingConventionDrawer from './namingConvention/NamingConventionDrawer';
import { createDefaultPlans } from '../../../actions/channel/create';
import { fetchChannelOverview } from '../../../actions/channel/fetch';
import { ExportOption, FileExportOption } from '../../../../types/import_export';
import { hasPermission } from '../../../utils/Permissions';

export enum FormSegments {
  FILE = 'FILE',
  SCHEDULE = 'SCHEDULE',
  DELIVERY = 'DELIVERY',
}

type EditChannelProps = {
  dispatch: AsyncDispatch;
  create: boolean;
  createIntegratedId?: number;
  deliveryFrequencies: ExtendedResources[];
  deliveryTypes: ExtendedResources[];
  ftpModes: ExtendedResources[];
  ftpDirectoryTypes: ExtendedResources[];
  protocolTypes: ExtendedResources[];
  encryptionTypes: ExtendedResources[];
  fileTypes: FileExportOption[];
  fileNameConfigOptions: StandardResourceCodeDescription[];
  channel?: Channel;
  brands: Brand[];
  allAccessableBrands: ReceiverCatalogueBrandType[];
  exportTemplatesAdvanced: ExportBuilderAdvancedTemplate[];
  defaultFileNameTemplate: StandardResourceConfig;
  fileNameTemplates: FileNameTemplate[];
  isReceiverUser: boolean;
  fetchingExportOptions: boolean;
  canManageAdvancedExportOptions: boolean;
  createChannel: (values: { [key: string]: any }) => Promise<any>;
  updateChannel: (values: { [key: string]: any }) => Promise<any>;
  createDefaultSettings: (
    val: { [key: string]: any },
    fileExportOptions: ChannelFileExportOption[]
  ) => void;
  updateDefaultSettings: (
    val: { [key: string]: any },
    fileExportOptions: ChannelFileExportOption[]
  ) => Promise<any>;
  deletePlans: (planIds: number[]) => Promise<any>;
  onClose: (force?: boolean) => void;
} & WithTranslation;

type EditChannelState = {
  customChannel: boolean;
  newChannelId?: number;
  showNamingConventionDrawer: boolean;
  currentFileTypeId: number;
};

export class EditChannel extends React.Component<EditChannelProps, EditChannelState> {
  constructor(props: EditChannelProps) {
    super(props);
    this.state = {
      customChannel: props.channel ? !props.channel.integration_type_id : true,
      newChannelId: undefined,
      showNamingConventionDrawer: false,
      currentFileTypeId: 1,
    };
  }

  getChannelDefaultSegments = () => {
    const { channel } = this.props;
    return [
      !!channel?.default_ftp && FormSegments.DELIVERY,
      FormSegments.FILE,
      !!channel?.default_schedule && FormSegments.SCHEDULE,
    ]
      .filter(segment => segment !== false)
      .sort() as FormSegments[];
  };

  getDefaultBrandPlans = () => {
    const { channel, brands, isReceiverUser, allAccessableBrands } = this.props;
    return brands.map(brand => {
      const plans = channel?.plans || [];
      const brandPlans = plans.filter(p => p.default && p.brand_ids.includes(brand.id));
      const typeIds = brandPlans.map(plan => plan.file_type_id).sort((a, b) => a - b);
      const accessableBrand = isReceiverUser
        ? allAccessableBrands.find(b => b.id === brand.id)
        : undefined;
      return {
        brand: brand.name,
        brandId: brand.id,
        brandCode: brand.code,
        authorizedByBrandAt: accessableBrand?.authorized_by_brand_at || undefined,
        fileTypes: [...new Set(typeIds)],
      };
    });
  };

  getChannelDefPlans = () => {
    const { channel, brands } = this.props;
    return brands.map(brand => {
      const plans = channel?.plans || [];
      const brandPlans = plans.filter(p => p.default && p.brand_ids.includes(brand.id));
      return { brandId: brand.id, brandPlans };
    });
  };

  getExportFileType = (fileTypeId: number | null) => {
    const findExportOptions = this.props.fileTypes.find(
      fileTypeOption => fileTypeOption.id === fileTypeId
    );
    return findExportOptions;
  };

  getAllowedOptions = (fileType?: FileExportOption) => {
    if (fileType && fileType.export_options)
      return this.props.canManageAdvancedExportOptions
        ? fileType?.export_options
        : fileType?.export_options.filter(option => option.advanced === 0);
    return [];
  };

  getExportOptionsSchema = (value: any) => {
    const { t } = this.props;
    if (typeof value === 'number') return Yup.number().required(t('validation:required'));
    if (typeof value === 'object') {
      const length = value ? value.length - 1 : 0;
      if (length > 0) return Yup.array();
      return Yup.array().nullable().required(t('validation:required'));
    }
    return Yup.string().required(t('validation:required'));
  };

  schema = () => {
    const fileExportOption: FileExportOption | undefined = this.getExportFileType(
      this.state.currentFileTypeId!
    );
    const filteredExportOptions = this.getAllowedOptions(fileExportOption);

    const mandatoryExportOptions = filteredExportOptions?.filter(option => option.mandatory === 1);

    const exportOptionsSchema: any = {};

    mandatoryExportOptions?.forEach(f => {
      exportOptionsSchema[f.code] = Yup.lazy(value => this.getExportOptionsSchema(value));
    });

    return exportOptionsSchema;
  };

  getFileName = () =>
    this.props.fileTypes.find(fileType => fileType.id === this.state.currentFileTypeId)?.name;

  // validate only active form sections
  getValidationSchema = () => {
    const fileTypeName = this.getFileName();

    return Yup.object().shape({
      name: Yup.string().required(validationMessages.required),
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string().email(),
      phone: Yup.string(),
      schedule: Yup.object().shape(scheduleValidationSchema),
      delivery: Yup.object().shape(deliveryValidationSchema),
      exportOptions: Yup.object().shape({
        [fileTypeName!]: Yup.object().shape({
          ...this.schema(),
        }),
      }),
    });
  };

  handleChannelChange = (values: FormikValues) => {
    if (this.props.create) return this.props.createChannel(values);
    return this.props.updateChannel(values);
  };

  openNamingConventionDrawer = () => this.setState({ showNamingConventionDrawer: true });

  handleDefaultPlanChange = (values: FormikValues) => {
    const { channel, deletePlans } = this.props;
    const removedPlanIds: number[] = [];
    const channelDefaultPlans = this.getChannelDefPlans();

    values.defBrandPlans.forEach((brand: any) => {
      const brandData = channelDefaultPlans.find(def => def.brandId === brand.brandId);
      brandData!.brandPlans.forEach((plan: Plan) => {
        if (plan.default && !brand.fileTypes.includes(plan.file_type_id))
          removedPlanIds.push(plan.id);
      });
    });

    const promises = [];

    const channelDefaultTypes = {
      channel_id: channel!.id,
      brands: values.defBrandPlans.map((brand: any) => ({
        id: brand.brandId,
        file_type_ids: brand.fileTypes,
      })),
    };

    if (removedPlanIds.length > 0) promises.push(deletePlans(removedPlanIds));
    promises.push(this.props.dispatch(createDefaultPlans(channelDefaultTypes)));

    return Promise.all(promises).then(() => this.props.dispatch(fetchChannelOverview()));
  };

  handleDefaultChange = (
    values: FormikValues,
    fileExportOptions: ChannelFileExportOption[]
  ): Promise<any> => {
    const initialDefaultValues = this.getChannelDefaultSegments();

    const createValues = {
      ...(!initialDefaultValues.includes(FormSegments.FILE) ? { file: values.file } : {}),
      ...(!initialDefaultValues.includes(FormSegments.DELIVERY)
        ? { delivery: values.delivery }
        : {}),
      ...(!initialDefaultValues.includes(FormSegments.SCHEDULE)
        ? { schedule: values.schedule }
        : {}),
    };

    const filteredValues = {
      file: values.file,
      delivery: values.delivery,
      schedule: values.schedule,
    };

    if (this.props.create)
      return Promise.resolve(this.props.createDefaultSettings(createValues, fileExportOptions));
    return Promise.resolve(this.props.updateDefaultSettings(filteredValues, fileExportOptions));
  };

  resetAdvancedOptions = (
    fileTypeId: number,
    fileTypeName: string,
    setFieldValue: FormikHelpers<any>['setFieldValue']
  ) => {
    const advancedExportOptions = this.getExportFileType(fileTypeId)?.export_options.filter(
      option => option.advanced === 1
    );
    advancedExportOptions?.forEach(option => {
      if (option.format_type_id === 1 || option.format_type_id === 2)
        setFieldValue(
          `exportOptions[${fileTypeName}].${option.code}`,
          option.default_value && Number(option.default_value)
        );
      if (option.format_type_id === 3)
        setFieldValue(
          `exportOptions[${fileTypeName}].${option.code}`,
          option.default_value ? Number(option.default_value) : option.default_value || null
        );
      if ([4, 6].includes(option.format_type_id))
        setFieldValue(`exportOptions[${fileTypeName}].${option.code}`, option.default_value || []);
      if (option.format_type_id === 5)
        setFieldValue(`exportOptions[${fileTypeName}].${option.code}`, option.default_value);
    });
  };

  getValue = (
    option: ExportOption,
    existingOption?: ChannelExportOptions,
    getDefault?: boolean
  ) => {
    // value for submission type should not be null => use default fallback
    if (option.code === 'submission_type')
      return existingOption?.parameters || (option.default_value && Number(option.default_value));
    if (!getDefault)
      return existingOption?.parameters || Number(existingOption?.parameters) === 0
        ? existingOption?.parameters
        : null;
    return option.default_value && Number(option.default_value);
  };

  getExportOptionsInitValues = () => {
    const { channel, fileTypes } = this.props;

    const initValues: Record<string, Record<string, number[] | null | string>> = {};
    fileTypes.forEach(fileType => {
      const optionInitValues: Record<string, any> = {};
      const filteredExportOptions = this.getAllowedOptions(fileType);

      const exisistingtValues =
        (channel?.default_file &&
          channel?.default_file?.find(option => option.file_type_id === fileType.id)) ||
        undefined;
      filteredExportOptions?.forEach(option => {
        const existingOption = exisistingtValues?.export_options.find(
          op => op.option_id === option.id
        );
        if (option.format_type_id === 1 || option.format_type_id === 2) {
          optionInitValues[option.code] = this.getValue(option, existingOption, !channel?.id);
        }
        if (option.format_type_id === 3) {
          optionInitValues[option.code] = channel?.id
            ? existingOption?.parameters || null
            : (option.default_value && Number(option.default_value)) || option.default_value;
        }
        if ([4, 6].includes(option.format_type_id)) {
          optionInitValues[option.code] = channel?.id
            ? existingOption?.parameters || []
            : option.default_value || [];
        }
        if (option.format_type_id === 5)
          optionInitValues[option.code] = channel?.id
            ? existingOption?.parameters || ''
            : option.default_value;
      });
      initValues[fileType.name] = optionInitValues;
    });
    return initValues;
  };

  getAdvancedOptionsFileTypeIds = () => {
    const { channel, fileTypes } = this.props;
    const fileTypesWithAdvOptions = fileTypes.filter(fileType => {
      const filteredExportOptions = this.getAllowedOptions(fileType).filter(
        option => option.advanced === 1
      );

      const existingValues =
        (channel?.default_file &&
          channel?.default_file?.find(option => option.file_type_id === fileType.id)) ||
        undefined;
      const value = filteredExportOptions?.find(option => {
        const x = existingValues?.export_options.find(o => o.option_id === option.id)?.parameters;
        if ((option.format_type_id === 1 || option.format_type_id === 2) && x) {
          const defaultValue = option?.default_value
            ? Number(option.default_value)
            : option.default_value;
          if (typeof defaultValue !== typeof x) return true;
          if (typeof defaultValue === typeof x && x !== defaultValue) return true;
        }
        if (option.format_type_id === 3) return x && Number(option.default_value) !== Number(x);
        if ([4, 5, 6].includes(option.format_type_id)) return Array.isArray(x) && x?.length > 0;
      });
      return !!value;
    });
    const ids = fileTypesWithAdvOptions.map(f => f.id);
    return ids;
  };

  getInitialValues = () => {
    const { channel, create } = this.props;
    // default settings are mandatory now since default plans
    const defaultFtp = !create ? channel?.default_ftp || ({} as FTP) : undefined;
    const defaultSchedule = !create
      ? channel?.default_schedule || ({} as DefaultSchedule)
      : undefined;

    return {
      exportOptions: this.getExportOptionsInitValues(),
      advOptionFileTypeIds: this.getAdvancedOptionsFileTypeIds(),
      name: channel ? channel.name || '' : '',
      firstName: channel ? channel.first_name || '' : '',
      lastName: channel ? channel.last_name || '' : '',
      email: channel ? channel.email || '' : '',
      phone: channel ? channel.phone || '' : '',
      delivery: {
        deliveryTypeId: defaultFtp ? defaultFtp.delivery_type_id : 1,
        ftpDirectoryTypeId: defaultFtp ? defaultFtp.ftp_directory_type_id : 1,
        ftpModeId: defaultFtp ? defaultFtp.ftp_mode_id : 1,
        protocolTypeId: defaultFtp ? defaultFtp.protocol_type_id : 1,
        ftpEncryptionTypeId: defaultFtp ? defaultFtp.ftp_encryption_type_id : 1,
        customDirectory: defaultFtp ? defaultFtp.custom_directory || '' : '',
        server: defaultFtp ? defaultFtp.server : '',
        user: defaultFtp ? defaultFtp.user_name : '',
        password: defaultFtp ? defaultFtp.password : '',
        port: (defaultFtp && defaultFtp.port) || 21,
        email: defaultFtp ? defaultFtp.email || '' : '',
      },
      file: {
        fileNameTemplateId: channel?.file_name_template_id || undefined,
      },
      schedule: {
        deliveryFrequencyId: defaultSchedule ? defaultSchedule.delivery_frequency_id : 3,
        deliveryDay: defaultSchedule ? defaultSchedule.delivery_day : 1,
        deliveryDate: defaultSchedule ? defaultSchedule.delivery_date : 1,
        deliveryTime: defaultSchedule ? defaultSchedule.delivery_time : '00:00',
      },
      defBrandPlans: this.getDefaultBrandPlans(),
    };
  };

  channelForm = () => {
    const { t, fileTypes, fetchingExportOptions } = this.props;
    if (fetchingExportOptions) return <Spin className="spinner-center" />;
    return (
      <Formik
        initialValues={this.getInitialValues()}
        validationSchema={this.getValidationSchema()}
        onSubmit={(values, { setSubmitting, resetForm, setFieldError }) => {
          const fileExportOptions: ChannelFileExportOption[] = [];
          fileTypes?.forEach(fileType => {
            const fileExportOption: FileExportOption | undefined = this.getExportFileType(
              fileType.id
            );
            const filteredExportOptions = values.advOptionFileTypeIds.includes(fileType.id)
              ? this.getAllowedOptions(fileExportOption)
              : this.getAllowedOptions(fileExportOption).filter(option => option.advanced === 0);

            const defaultExportOptions: ChannelExportOptions[] = filteredExportOptions?.map(
              option => {
                return {
                  option_id: option.id,
                  parameters: values.exportOptions[fileType.name][option.code],
                };
              }
            );

            fileExportOptions.push({
              file_type_id: fileType.id,
              export_options: defaultExportOptions,
            });
          });

          this.handleChannelChange(values)
            .then(result => {
              this.setState({ newChannelId: result.value.data.id });
              Promise.all([
                this.handleDefaultPlanChange(values),
                this.handleDefaultChange(values, fileExportOptions),
              ])
                .then(() => {
                  setSubmitting(false);
                  resetForm({ values });
                })
                .catch(() => {
                  setSubmitting(false);
                });
            })
            .catch(result => {
              setSubmitting(false);
              if (result.response.status === 422) {
                message.error(t('channel:channelExisting'));
                setFieldError('name', t('channel:channelExisting'));
              }
            });
        }}
      >
        {({ values, handleSubmit, isSubmitting, handleChange, setFieldValue, dirty }) => (
          <React.Fragment>
            <Form layout="vertical" className="h-full flex flex-col">
              <ChannelFormDirtyHandler />
              <FormErrorFocus />
              <div className="flex-1 overflow-auto pr-5 pl-5 pt-5">
                <Row gutter={20} align="bottom">
                  {this.state.customChannel && (
                    <Col span={12}>
                      <FormInput
                        name="name"
                        label={t('channel:channelName')}
                        placeholder={t('channel:channelName')}
                        handleChange={handleChange}
                        required
                      />
                    </Col>
                  )}
                </Row>

                <div>
                  <div className="ant-text-black mb-1">{t('channel:brandList')}</div>

                  <ChannelPlanList
                    brandPlans={values.defBrandPlans}
                    isReceiverUser={this.props.isReceiverUser}
                    fileTypes={this.props.fileTypes}
                    onChange={(typeId, brandId) => {
                      const changedPlans = values.defBrandPlans.map(brandPlan => {
                        if (brandPlan.brandId === brandId) {
                          const types = brandPlan.fileTypes.includes(typeId)
                            ? brandPlan.fileTypes.filter(id => id !== typeId)
                            : [...brandPlan.fileTypes, typeId].sort((a, b) => a - b);
                          return { ...brandPlan, fileTypes: types };
                        }
                        return brandPlan;
                      });
                      setFieldValue('defBrandPlans', changedPlans);
                    }}
                    onColumnSelect={typeId => {
                      const checkCount = values.defBrandPlans.filter(p =>
                        p.fileTypes.includes(typeId)
                      ).length;
                      const allSelected = checkCount === values.defBrandPlans.length;

                      const changedPlans = values.defBrandPlans.map(brandPlan => {
                        const types = allSelected
                          ? brandPlan.fileTypes.filter(id => id !== typeId)
                          : [...new Set([...brandPlan.fileTypes, typeId])].sort((a, b) => a - b);
                        return { ...brandPlan, fileTypes: types };
                      });
                      setFieldValue('defBrandPlans', changedPlans);
                    }}
                    onRowSelect={brandId => {
                      const changedPlans = values.defBrandPlans.map(brandPlan => {
                        if (brandPlan.brandId === brandId) {
                          const checkCount = brandPlan.fileTypes.length;
                          const types =
                            checkCount === fileTypes.length ? [] : fileTypes.map(t => t.id);
                          return { ...brandPlan, fileTypes: types };
                        }
                        return brandPlan;
                      });
                      setFieldValue('defBrandPlans', changedPlans);
                    }}
                  />
                </div>

                <ChannelDefaultSettings
                  handleFileTypeChange={fileTypeId =>
                    this.setState({ currentFileTypeId: fileTypeId! })
                  }
                  fileTypeName={this.getFileName()}
                  resetAdvancedOptions={(typeId: number, typeName: string) =>
                    this.resetAdvancedOptions(typeId, typeName, setFieldValue)
                  }
                  fileExportOptions={this.props.fileTypes}
                  disabled={this.props.create && !this.state.newChannelId}
                  deliveryFrequencies={this.props.deliveryFrequencies}
                  deliveryTypes={this.props.deliveryTypes}
                  ftpModes={this.props.ftpModes}
                  ftpDirectoryTypes={this.props.ftpDirectoryTypes}
                  protocolTypes={this.props.protocolTypes}
                  encryptionTypes={this.props.encryptionTypes}
                  fileTypes={this.props.fileTypes}
                  fileNameConfigOptions={this.props.fileNameConfigOptions}
                  exportTemplatesAdvanced={this.props.exportTemplatesAdvanced}
                  fileNameTemplates={this.props.fileNameTemplates}
                  defaultFileNameTemplate={this.props.defaultFileNameTemplate}
                  canManageAdvancedExportOptions={this.props.canManageAdvancedExportOptions}
                  openNamingConventionDrawer={this.openNamingConventionDrawer}
                />

                <Alert
                  message={t('channel:settingsHint')}
                  type="info"
                  style={{ marginBottom: '15px' }}
                  showIcon
                />
              </div>

              <div className="drawer-submit__bottom flex-col items-end">
                <div>
                  <Button
                    onClick={() => this.props.onClose(true)}
                    className="drawer-submit__bottom-cancel"
                  >
                    {t('common:cancel')}
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => handleSubmit()}
                    loading={isSubmitting}
                    disabled={!dirty || isSubmitting}
                  >
                    {this.props.create && !this.state.newChannelId
                      ? t('common:create')
                      : t('common:save')}
                  </Button>
                </div>
              </div>
            </Form>
          </React.Fragment>
        )}
      </Formik>
    );
  };

  render() {
    const { t, channel, createIntegratedId } = this.props;
    const { customChannel } = this.state;

    if (createIntegratedId || (!customChannel && channel?.integration_type_id !== 8))
      return (
        <Card className="channel__contact-card">
          <p>{t('channel:contactChannelSetup')}</p>
        </Card>
      );

    return (
      <React.Fragment>
        {this.channelForm()}
        <NamingConventionDrawer
          visible={this.state.showNamingConventionDrawer}
          onClose={() => this.setState({ showNamingConventionDrawer: false })}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  deliveryFrequencies: state.resources.data.channel.delivery_frequencies,
  deliveryTypes: state.resources.data.channel.delivery_types,
  ftpModes: state.resources.data.channel.ftp_modes,
  ftpDirectoryTypes: state.resources.data.channel.ftp_directory_types,
  protocolTypes: state.resources.data.channel.protocol_types,
  encryptionTypes: state.resources.data.channel.ftp_encryption_types,
  fileNameConfigOptions: state.resources.data.global.file_name_config_options,
  defaultFileNameTemplate: state.resources.data.global.file_name_templates[0],
  fileNameTemplates: state.channel.channels.fileNameTemplates,
  exportTemplatesAdvanced: state.brand.exportBuilderAdvanced.templates,
  fetchingExportOptions: state.brand.brandExport.fetchingExportOptions,
  canManageAdvancedExportOptions: hasPermission(
    state.user.user,
    'can_manage_advanced_export_options'
  ),
});

export default connect(mapStateToProps)(withContainerWrapper(withTranslation()(EditChannel)));
