import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import React from 'react';
import { AutoSizer } from 'react-virtualized';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as utils from '../../../utils/Utils';
import { ListUser, UserPermissionRole } from '../../../../types/user';

type UserTableProps = {
  userList: ListUser[];
  filterKeywords: string;
  roles: UserPermissionRole[];
  editUser: (userId: number) => void;
} & WithTranslation;

class UserTable extends React.Component<UserTableProps> {
  private tableRef = React.createRef<HTMLDivElement>();

  filterUsers = () => {
    const { userList, filterKeywords } = this.props;
    const keywords = utils.stringToArray(filterKeywords.toLowerCase());

    return userList.filter(user => {
      const firstName = user.first_name.toLowerCase();
      const lastName = user.last_name.toLowerCase();
      return keywords.every(keyword => firstName.includes(keyword) || lastName.includes(keyword));
    });
  };

  createTableRow = (user: ListUser) => {
    const { roles, t } = this.props;

    return {
      key: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      type: user.admin ? t('user:admin') : t('user:user'),
      roles: user.user_role_ids
        .map(id => {
          const role = roles.find(role => role.id === id);
          return role ? role.name : '';
        })
        .join(', '),
      status: user.active ? t('user:active') : t('user:inactive'),
      actions: (
        <Button icon={<EditOutlined />} onClick={() => this.props.editUser(user.id)}>
          {t('user:edit')}
        </Button>
      ),
    };
  };

  render() {
    const { userList, filterKeywords, t } = this.props;

    const columns = [
      { title: t('user:firstName'), dataIndex: 'firstName' },
      { title: t('user:lastName'), dataIndex: 'lastName' },
      { title: t('user:email'), dataIndex: 'email' },
      { title: t('user:type'), dataIndex: 'type', width: '180px' },
      { title: t('user:roles'), dataIndex: 'roles' },
      { title: t('user:status'), dataIndex: 'status', width: '130px' },
      { dataIndex: 'actions', hideHeader: true, width: '100px' },
    ];

    const users = filterKeywords ? this.filterUsers() : userList;

    const dataSource = users.map(user => {
      return this.createTableRow(user);
    });

    const tableHeight = this.tableRef.current ? this.tableRef.current.offsetHeight : undefined;

    return (
      <div className="user-content__wrapper">
        <AutoSizer>
          {({ height, width }) => (
            <div style={{ height, width }}>
              <div ref={this.tableRef}>
                <Table
                  className="user-table"
                  columns={columns}
                  dataSource={dataSource}
                  pagination={false}
                  size="middle"
                  scroll={tableHeight && tableHeight > height ? { y: height - 46 } : undefined}
                />
              </div>
            </div>
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default withTranslation()(UserTable);
