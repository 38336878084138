import axios from 'axios';
import { fetchItemAnalyses, fetchItemAnalysesBySegment } from '../../items/analyses/fetch';
import { fetchSmallShopPreview } from '../../items/shopPreview/fetch';
import { hasChannelDBPerm } from '../../../utils/Permissions';
import { increaseOutOfSyncCount } from '../../brand/synchronisation';
import { resetUpdateCounter } from '../updateManager/update';
import { selectItem } from './update';
import { getApiRoot } from '../../../constants/ParamountReactConstants';
import * as utils from '../../../utils/Utils';
import { Item } from '../../../../types/item';
import { ApplicationState } from '../../../reducers';
import { fetchExtendedItem } from '../../items/item/fetch';
import { Order, OrderOption } from '../../../../types/catalogue';

const CancelToken = axios.CancelToken;

let fetchSelectedValuesCancelToken: any;

export function fetchItemsShortNames(itemIds: number[], languageId: string | null) {
  const params = utils.createURL([
    { name: 'all_item_ids', values: itemIds },
    { name: 'language_id', values: languageId },
  ]);
  return {
    type: 'UPDATE_SESSION_ITEMS',
    meta: { itemIds },
    payload: axios.get(`${getApiRoot()}/items/short_names${params}`),
  };
}

export function fetchAllItemInfos(itemId: number) {
  return (dispatch: any) => {
    dispatch(fetchExtendedItem(itemId));
    dispatch(selectItem(itemId));
    dispatch(fetchItemAnalyses(itemId, true));
    dispatch(fetchSmallShopPreview(itemId));
  };
}

export function fetchAllItemInfosReceiver(itemId: number) {
  return (dispatch: any) => {
    dispatch(fetchExtendedItem(itemId));
    dispatch(selectItem(itemId));
    dispatch(fetchSmallShopPreview(itemId));
  };
}

let fetchItemsCanceltoken: any;

export function fetchItemsByFilterId({
  filterId,
  page = 1,
  filterBrandIds = [],
  selectedReceiverId,
  selectedChannelId,
  languageId,
  order,
}: {
  filterId: number;
  page?: number;
  filterBrandIds?: number[];
  selectedReceiverId?: number | null;
  selectedChannelId?: number | null;
  languageId?: string | null;
  order?: Order | null;
}) {
  if (fetchItemsCanceltoken) {
    fetchItemsCanceltoken();
  }
  const urlParams = utils.createURL([
    { name: 'limit', values: 100 },
    { name: 'page', values: page },
    { name: 'filter_brand_ids', values: filterBrandIds },
    { name: 'delivery_receiver_id', values: selectedReceiverId },
    { name: 'delivery_channel_id', values: selectedChannelId },
    { name: 'language_id', values: languageId },
    { name: 'order_condition', values: order && order.condition },
    ...(order && order.option === OrderOption.DESCENDING
      ? [{ name: order.option, values: 1 }]
      : []),
  ]);

  return {
    meta: { page },
    type: 'FETCH_ITEMS_BY_FILTER',
    payload: axios.get(`${getApiRoot()}/filters/${filterId}/items${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchItemsCanceltoken = c;
      }),
    }),
  };
}

let fetchItemsSummaryCanceltoken: any;

export function fetchItemsSummary(brandId: number) {
  if (fetchItemsSummaryCanceltoken) fetchItemsSummaryCanceltoken();

  const params = utils.createURL([{ name: 'parent_owner_brand_id', values: brandId }]);
  return {
    type: 'FETCH_ITEMS_SUMMARY',
    payload: axios.get(`${getApiRoot()}/items/summary${params}`, {
      cancelToken: new CancelToken(c => {
        fetchItemsSummaryCanceltoken = c;
      }),
    }),
  };
}

export function fetchReceiverItemsSummary(receiverId: number) {
  const params = utils.createURL([{ name: 'receiver_id', values: receiverId }]);
  return {
    type: 'FETCH_ITEMS_SUMMARY',
    payload: axios.get(`${getApiRoot()}/items/summary${params}`),
  };
}

let fetchBrandItemTagsCanceltoken: any;

export function fetchBrandItemTags(brandId: number) {
  if (fetchBrandItemTagsCanceltoken) fetchBrandItemTagsCanceltoken();

  const urlParams = utils.createURL([{ name: 'brand_id', values: brandId }]);
  return {
    type: 'FETCH_ITEM_TAGS',
    payload: axios.get(`${getApiRoot()}/items/tags${urlParams}`, {
      cancelToken: new CancelToken(c => {
        fetchBrandItemTagsCanceltoken = c;
      }),
    }),
  };
}

export function fetchReceiverItemTags(receiverId: number) {
  const urlParams = utils.createURL([{ name: 'receiver_id', values: receiverId }]);
  return {
    type: 'FETCH_ITEM_TAGS',
    payload: axios.get(`${getApiRoot()}/items/tags${urlParams}`),
  };
}

export function fetchItemTags({
  brandId,
  filterId,
  receiverId,
}: {
  filterId: number;
  brandId?: number;
  receiverId?: number;
}) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'brand_id', values: brandId },
    { name: 'receiver_id', values: receiverId },
  ]);
  return {
    type: 'FETCH_SELECTED_ITEM_TAGS',
    payload: axios.get(`${getApiRoot()}/items/tags${urlParams}`),
  };
}

export function fetchItemsDataAfterUpdate(
  selectedItems: Item[],
  selectedItem: Item,
  activeArea: string,
  languageId: string | null
) {
  const updateIds = selectedItems.map(item => item.id);
  return async (dispatch: any, getState: () => ApplicationState) => {
    // await - performance reasons
    await dispatch(fetchItemAnalysesBySegment(updateIds, activeArea));
    const fetchedUpdatedItems: any = await dispatch(fetchItemsShortNames(updateIds, languageId));
    if (selectedItem) await dispatch(fetchSmallShopPreview(selectedItem.id));
    dispatch(resetUpdateCounter());

    const user = getState().user.user;
    if (hasChannelDBPerm(user)) {
      // check updated items for new out of sync count
      const updatedItems: Item[] = fetchedUpdatedItems.action.payload.data.items;

      const prevUpdatedItemsOutOfSyncCount = selectedItems.filter(
        item => new Date(item.last_synced_at || 0) < new Date(item.updated_at)
      ).length;
      const newUpdatedItemsCountOutOfSyncCount = updatedItems.filter(
        item => new Date(item.last_synced_at || 0) < new Date(item.updated_at)
      ).length;

      const increasedOutOfSyncCount =
        newUpdatedItemsCountOutOfSyncCount - prevUpdatedItemsOutOfSyncCount;
      if (increasedOutOfSyncCount > 0) {
        await dispatch(increaseOutOfSyncCount(increasedOutOfSyncCount));
        dispatch(resetUpdateCounter());
      }
    }
  };
}

export function fetchSelectedItemsLists({
  brandId,
  filterId,
  filterBrandIds,
  itemIds,
  languageId,
}: {
  brandId?: number;
  filterId?: number;
  filterBrandIds?: number[];
  itemIds?: number[];
  languageId?: number;
}) {
  if (fetchSelectedValuesCancelToken) fetchSelectedValuesCancelToken();

  const urlParams = utils.createURL([
    { name: 'brand_id', values: brandId },
    { name: 'filter_id', values: filterId },
    { name: 'filter_brand_ids', values: filterBrandIds },
    { name: 'language_id', values: languageId },
  ]);

  return {
    type: 'FETCH_SELECTED_ITEMS_LIST',
    payload: axios.post(
      `${getApiRoot()}/items/list${urlParams}`,
      { item_ids: itemIds },
      {
        cancelToken: new CancelToken(c => {
          fetchSelectedValuesCancelToken = c;
        }),
      }
    ),
  };
}
