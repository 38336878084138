import React from 'react';
import { Alert, Button, Col, Form, Row } from 'antd';
import * as Yup from 'yup';
import { Field, FieldProps, Formik } from 'formik';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withTracker } from '../containers/withTracker';
import { withRouter, WithRouterProps } from '../containers/withRouter';
import { register, registerReceiver } from '../actions/user';
import * as globalActions from '../actions/app/global';
import validationMessages from '../constants/ValidationMessages.json';
import { UserRole } from '../../types/user';
import { AsyncDispatch } from '../../types/global';
import FormInput from '../components/global/Forms/FormInput';
import FormPasswordInput from '../components/global/Forms/FormPasswordInput';
import { privacyUrl, termsUrl } from '../constants/ParamountReactConstants';
import { ApplicationState } from '../reducers';
import ThreeLevelDropdown from '../components/global/ThreeLevelDropdown';
import brandImage from '../../images/icons/register-brand.svg';
import resellerImage from '../../images/icons/register-reseller.svg';
import emailImage from '../../images/icons/register-email.svg';
import LoginFooter from './LoginFooter';

const CommonSchemaValidation = {
  firstName: Yup.string()
    .min(2, validationMessages.tooShort)
    .max(50, validationMessages.tooLong)
    .trim(validationMessages.leadingWhitespace)
    .strict()
    .matches(/^[^0-9]+$/, validationMessages.validateNoNumbers)
    .required(validationMessages.required),
  lastName: Yup.string()
    .min(2, validationMessages.tooShort)
    .max(50, validationMessages.tooLong)
    .trim(validationMessages.leadingWhitespace)
    .strict()
    .matches(/^[^0-9]+$/, validationMessages.validateNoNumbers)
    .required(validationMessages.required),
  email: Yup.string().email(validationMessages.invalidEmail).required(validationMessages.required),
  password: Yup.string()
    .min(6, validationMessages.passwordLength)
    .required(validationMessages.required),
};

const RegisterReceiverSchema = Yup.object().shape({
  receiverName: Yup.string()
    .min(2, validationMessages.tooShort)
    .max(50, validationMessages.tooLong)
    .trim(validationMessages.leadingWhitespace)
    .strict()
    .required(validationMessages.required),
  ...CommonSchemaValidation,
});

const RegisterManufacturerSchema = Yup.object().shape({
  parentId: Yup.number().when(['parentName'], (otherCompany: string, schema: Yup.NumberSchema) =>
    !otherCompany ? schema.required(validationMessages.required) : schema
  ),
  parentName: Yup.string(),
  ...CommonSchemaValidation,
});

type ManufacturerFormFields = {
  parentId: number | undefined;
  parentName: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

type RegisterPageProps = {
  registrationResources: any;
  fetchingRegistrationResources: boolean;
  dispatch: AsyncDispatch;
} & WithTranslation &
  WithRouterProps;

type RegisterPageState = {
  showRegisterFor?: UserRole;
  registeredEmail: string;
  registrationSuccessful: boolean;
  error: string | null;
};

class RegisterPage extends React.Component<RegisterPageProps, RegisterPageState> {
  constructor(props: RegisterPageProps) {
    super(props);
    let registerSelection: UserRole | undefined;

    if (location.pathname === '/register/brand') registerSelection = UserRole.MANUFACTURER;
    if (location.pathname === '/register/reseller') registerSelection = UserRole.RECEIVER;

    this.state = {
      showRegisterFor: registerSelection,
      registeredEmail: '',
      registrationSuccessful: false,
      error: null,
    };
  }

  componentDidMount() {
    if (!this.props.registrationResources) {
      this.props.dispatch(globalActions.fetchRegistrationResources());
    }
  }

  registerIntercomSignup = (
    firstName: string,
    lastName: string,
    email: string,
    otherCompany: string,
    parentId: number | string
  ) => {
    if (process.env.REACT_APP_STAGE && !email.includes('@pdm.ai')) {
      const name = `${firstName} ${lastName}`;
      const seconds = new Date().getTime() / 1000;
      const company = {
        id: parentId,
        name:
          this.props.registrationResources.find((reg: any) => reg.id === parentId)?.parent_name ||
          otherCompany,
      };
      if ((window as any).Intercom)
        (window as any).Intercom('update', {
          name,
          email,
          signed_up_at: seconds,
          company,
          company_name_at_signup: otherCompany, // custom field defined in intercom
          account_type: this.state.showRegisterFor === UserRole.RECEIVER ? 'Receiver' : 'Brand',
        });
    }
  };

  errorMessage = () => {
    if (this.state.error) {
      return (
        <Alert
          className="register__error-message"
          type="error"
          showIcon
          message={this.state.error}
        />
      );
    }
    return null;
  };

  successBox = () => {
    const { t } = this.props;
    const { registeredEmail } = this.state;

    return (
      <div className="register__success-box">
        <div className="register__success">
          <img src={emailImage} alt={t('common:email')} />
          <div className="login__title">{t('registerPage:registration_successful')}</div>
          <div className="mt-3 text-gray-700 text-xs">{t('registerPage:confirm_email')}</div>
          <div className="mt-3">{registeredEmail}</div>
          {/* todo: add button when backend endpoint is ready */}
          {/* <div className="mt-7">
            <Button>{t('registerPage:resend_email')}</Button>
          </div> */}
        </div>
      </div>
    );
  };

  registerInfo = () => {
    const { t } = this.props;

    return (
      <div className="text-xs text-center text-gray-700">
        {`${t('registerPage:sign_up_info')} `}
        <a className="register__link" href={termsUrl} target="_blank" rel="noopener noreferrer">
          {t('registerPage:terms')}
        </a>
        {` ${t('common:and')} `}
        <a className="register__link" href={privacyUrl} target="_blank" rel="noopener noreferrer">
          {t('registerPage:policy')}
        </a>
      </div>
    );
  };

  registerBoxReceiver = () => {
    const { t } = this.props;
    return (
      <React.Fragment>
        {this.errorMessage()}
        <Formik
          initialValues={{
            receiverName: '',
            firstName: '',
            lastName: '',
            email: '',
            password: '',
          }}
          validationSchema={RegisterReceiverSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.setState({ error: null });
            this.props
              .dispatch(registerReceiver(values))
              .then(result => {
                const receiver = result.value.data;
                this.registerIntercomSignup(
                  values.firstName,
                  values.lastName,
                  values.email,
                  values.receiverName,
                  receiver.receiver_id ? `r${receiver.receiver_id}` : ''
                );
                this.setState({ registrationSuccessful: true, registeredEmail: values.email });
                setSubmitting(false);
              })
              .catch(err => {
                const message = err.response?.data.message || err.message;
                this.setState({ error: message });
                setSubmitting(false);
              });
          }}
        >
          {({ handleSubmit, isSubmitting, isValid, dirty }) => (
            <Form layout="vertical" onFinish={() => handleSubmit()}>
              <FormInput
                name="receiverName"
                label={t('registerPage:label.receiver_name')}
                placeholder={t('registerPage:placeholder.company')}
                required
              />
              <Row gutter={20}>
                <Col span={12}>
                  <FormInput
                    name="firstName"
                    label={t('registerPage:label.first_name')}
                    placeholder={t('registerPage:placeholder.first_name')}
                    required
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    name="lastName"
                    label={t('registerPage:label.last_name')}
                    placeholder={t('registerPage:placeholder.last_name')}
                    required
                  />
                </Col>
              </Row>
              <FormInput
                name="email"
                label={t('registerPage:label.email')}
                placeholder={t('registerPage:placeholder.email')}
                type="email"
                required
              />
              <FormPasswordInput
                className="register__password-form-item"
                name="password"
                label={t('registerPage:label.password')}
                placeholder={t('registerPage:label.password')}
                type="password"
                required
              />
              <span className="text-xs">{t('registerPage:label.password_description')}</span>

              <Button
                type="primary"
                htmlType="submit"
                disabled={!(isValid && dirty) || isSubmitting}
                style={{ width: '100%', marginTop: 20, marginBottom: 10 }}
              >
                {t('registerPage:sign_up')}
              </Button>
              {this.registerInfo()}
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  };

  registerBoxManufacturer = () => {
    const { t, registrationResources, fetchingRegistrationResources } = this.props;
    const initialValues: ManufacturerFormFields = {
      parentId: undefined,
      parentName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    };

    return (
      <React.Fragment>
        {this.errorMessage()}
        <Formik
          initialValues={initialValues}
          validationSchema={RegisterManufacturerSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            this.setState({ error: null });
            this.props
              .dispatch(register(values as any))
              .then(result => {
                const manufacturer = result.value.data;
                this.setState({ registrationSuccessful: true, registeredEmail: values.email });
                this.registerIntercomSignup(
                  values.firstName,
                  values.lastName,
                  values.email,
                  values.parentName,
                  manufacturer.parent_id || undefined
                );
                setSubmitting(false);
              })
              .catch(err => {
                const { message } = err.response.data;
                this.setState({ error: message });
                setSubmitting(false);
              });
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            setFieldTouched,
            setValues,
            isSubmitting,
            touched,
            errors,
            values,
            isValid,
            dirty,
          }) => (
            <Form layout="vertical" onFinish={() => handleSubmit()}>
              <Field name="parentId">
                {({ field }: FieldProps) => (
                  <Form.Item
                    label={t('registerPage:label.company')}
                    hasFeedback
                    validateStatus={!!errors.parentId && touched.parentId ? 'error' : undefined}
                    help={(touched.parentId && errors.parentId) || undefined}
                    required
                  >
                    <ThreeLevelDropdown
                      elements={registrationResources}
                      selectedElement={field.value}
                      fetchingRegistrationResources={fetchingRegistrationResources}
                      onChange={(id?: number) => {
                        setValues({ ...values, parentId: id, parentName: '' });
                      }}
                      onBlur={() => setFieldTouched('parentId')}
                      addCompany={(name: string) => setFieldValue('parentName', name)}
                    />
                  </Form.Item>
                )}
              </Field>
              {!values.parentId && values.parentName && (
                <FormInput name="parentName" label={t('registerPage:label.other_company')} />
              )}
              <Row gutter={20}>
                <Col span={12}>
                  <FormInput
                    name="firstName"
                    label={t('registerPage:label.first_name')}
                    placeholder={t('registerPage:placeholder.first_name')}
                    required
                  />
                </Col>
                <Col span={12}>
                  <FormInput
                    name="lastName"
                    label={t('registerPage:label.last_name')}
                    placeholder={t('registerPage:placeholder.last_name')}
                    required
                  />
                </Col>
              </Row>
              <FormInput
                name="email"
                label={t('registerPage:label.email')}
                placeholder={t('registerPage:placeholder.email')}
                type="email"
                required
              />
              <FormPasswordInput
                className="register__password-form-item"
                name="password"
                label={t('registerPage:label.password')}
                placeholder={t('registerPage:label.password')}
                type="password"
                required
              />
              <span className="text-xs">{t('registerPage:label.password_description')}</span>

              <Button
                type="primary"
                htmlType="submit"
                disabled={!(isValid && dirty) || isSubmitting}
                style={{ width: '100%', marginTop: 20, marginBottom: 10 }}
              >
                {t('registerPage:sign_up')}
              </Button>
              {this.registerInfo()}
            </Form>
          )}
        </Formik>
      </React.Fragment>
    );
  };

  render() {
    const { registrationSuccessful, showRegisterFor } = this.state;
    const { t } = this.props;

    return (
      <div className="register__background">
        <div className="register__box">
          <div className="register__box-header">
            <div className="register-logo" />
          </div>
          {!registrationSuccessful && (
            <div className="register__box-content">
              <div className="register--header-content">
                {!showRegisterFor && (
                  <React.Fragment>
                    <div className="login__title">{t('registerPage:create_account')}</div>
                    <div className="mt-2">{t('registerPage:welcome_txt1')}</div>
                  </React.Fragment>
                )}
                {showRegisterFor === UserRole.MANUFACTURER && (
                  <React.Fragment>
                    <div className="login__title">{t('registerPage:create_brand_account')}</div>
                    <div className="mt-2">{t('registerPage:create_brand_info')}</div>
                  </React.Fragment>
                )}
                {showRegisterFor === UserRole.RECEIVER && (
                  <React.Fragment>
                    <div className="login__title">{t('registerPage:create_receiver_account')}</div>
                    <div className="mt-2">{t('registerPage:create_receiver_info')}</div>
                  </React.Fragment>
                )}
              </div>
              {!showRegisterFor && (
                <div className="flex m-auto mt-5">
                  <Button
                    className="register__large-button"
                    onClick={() => {
                      this.setState({ showRegisterFor: UserRole.MANUFACTURER });
                      this.props.navigate(`/register/brand`);
                    }}
                  >
                    <img src={brandImage} alt={t('registerPage:manufacturer')} />
                    <div className="text-base">{t('registerPage:manufacturer')}</div>
                    <div className="text-xs mt-2">{t('registerPage:brand_info')}</div>
                  </Button>
                  <Button
                    className="register__large-button ml-5"
                    onClick={() => {
                      this.setState({ showRegisterFor: UserRole.RECEIVER });
                      this.props.navigate(`/register/reseller`);
                    }}
                  >
                    <img src={resellerImage} alt={t('registerPage:receiver')} />
                    <div className="text-base">{t('registerPage:receiver')}</div>
                    <div className="text-xs mt-2">{t('registerPage:receiver_info')}</div>
                  </Button>
                </div>
              )}
              {this.state.showRegisterFor && (
                <div className="register__box-content__form">
                  {(this.state.showRegisterFor === UserRole.MANUFACTURER &&
                    this.registerBoxManufacturer()) ||
                    (this.state.showRegisterFor === UserRole.RECEIVER &&
                      this.registerBoxReceiver())}
                </div>
              )}
              <div className="register__login-container text-base">
                {`${t('registerPage:already_registered')} `}
                <Link className="register__login-text" to="/">
                  {t('registerPage:login_txt')}
                </Link>
              </div>
            </div>
          )}
          {registrationSuccessful && this.successBox()}
        </div>

        <LoginFooter />
      </div>
    );
  }
}

const mapStateToProps = (state: ApplicationState) => ({
  error: state.user.error,
  registrationResources: state.app.global.registrationResources,
  fetchingRegistrationResources: state.app.global.fetchingRegistrationResources,
});

export default withRouter(
  withTracker(connect(mapStateToProps)(withTranslation()(RegisterPage as any)))
);
