import axios from 'axios';
import { Dispatch } from 'redux';
import * as utils from '../../../utils/Utils';
import { getApiRoot } from '../../../constants/ParamountReactConstants';

import { multiCheckItems } from './update';

export function deleteItems({
  itemIds,
  filterId,
  withoutItemIds,
}: {
  itemIds?: number[];
  filterId?: number;
  withoutItemIds?: number[];
}): any {
  const params = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'item_ids', values: itemIds },
    { name: 'without_item_ids', values: withoutItemIds },
  ]);
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'DELETE_ITEMS',
      meta: { itemIds, withoutItemIds },
      payload: axios.delete(`${getApiRoot()}/items${params}`),
    });
    if (itemIds) dispatch(multiCheckItems(itemIds, false));
  };
}

export function deleteMarkItem(itemId: number) {
  const urlParams = utils.createURL([{ name: 'item_ids', values: itemId }]);

  return {
    type: 'DELETE_MARK_ITEM',
    meta: { itemId },
    payload: axios.delete(`${getApiRoot()}/items/marks${urlParams}`),
  };
}

export function deleteAllMarkItems({
  itemIds,
  filterId,
}: {
  itemIds?: number[];
  filterId?: number;
}) {
  const urlParams = utils.createURL([
    { name: 'item_ids', values: itemIds },
    { name: 'filter_id', values: filterId },
  ]);

  return {
    type: 'DELETE_MARK_ITEMS',
    meta: { itemIds },
    payload: axios.delete(`${getApiRoot()}/items/marks${urlParams}`),
  };
}

export function deleteTagForItems({
  itemIds,
  filterId,
  tagId,
  brandId,
  receiverId,
  withoutItemIds,
  filterBrandIds,
}: {
  itemIds?: number[];
  filterId?: number;
  tagId?: number;
  brandId?: number;
  receiverId?: number;
  withoutItemIds?: number[];
  filterBrandIds?: number[];
}) {
  const urlParams = utils.createURL([
    { name: 'filter_id', values: filterId },
    { name: 'item_ids', values: brandId ? itemIds : null },
    { name: 'receiver_item_ids', values: receiverId ? itemIds : null },
    { name: 'without_item_ids', values: withoutItemIds },
    { name: 'tag_id', values: tagId },
    { name: 'brand_id', values: brandId },
    { name: 'receiver_id', values: receiverId },
    { name: 'filter_brand_ids', values: filterBrandIds },
  ]);

  return {
    type: 'DELETE_TAG_FOR_ITEMS',
    meta: { itemIds, tagId, withoutItemIds },
    payload: axios.delete(`${getApiRoot()}/items/tags${urlParams}`),
  };
}
